import React from 'react';
import {
  useParams,
  useSearchParams,
  Link as RouterLink,
  useNavigate,
} from 'react-router-dom';
import Heading from '../../components/heading/heading';
import { Button, Link, Skeleton, Typography } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faFileCircleQuestion,
  faFileLines,
  faPencil,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { useQuery } from 'react-query';
import projectService from '../../service/project';
import LoadingMessage from '../../components/loading-message/loading-message';
import ErrorMessagePopup from '../../components/error-message/error-message-popup/error-message-popup';
import { Page } from '../../types/navigation';
import ProjectVersion from '../../components/project-version/project-version';
import { ReleaseStatus as ReleaseStatusType } from '../../types/project';
import Subtitle from '../../components/subtitle/subtitle';
import { AppRole, ProjectRole, ProjectRoleData, User } from '../../types/user';
import miscService from '../../service/misc';
import ProjectTeam from '../../components/project-team/project-team';
import LanguageList from '../../components/language-list/language-list';
import theme from '../../styles/theme';
import CreateReleaseModal from './create-release-modal/create-release-modal';
import Message from '../../components/message/message';
import useAuth from '../../util/auth-hook';
import { useTranslation } from 'react-i18next';
import ActivityLogPreview from '../../components/activity-log/activity-log-preview/activity-log-preview';

export type ProjectUserData = {
  user: User;
  role?: ProjectRoleData;
};

/**
 * Sorts the user data obtained in the useMemo below to:
 *  - Place the current user at the beginning
 *  - Sort the rest of the users in alphabetical order
 */
const sortUserData = (
  userData: ProjectUserData[],
  selfId: string,
): ProjectUserData[] => {
  const selfIndex = userData.findIndex((data) => data.user.id === selfId);

  const [selfData] = userData.splice(selfIndex, 1);
  return [
    selfData,
    ...userData.sort((a, b) =>
      a.user.name.full.toLowerCase() > b.user.name.full.toLowerCase() ? 1 : -1,
    ),
  ];
};

const ProjectDetails = () => {
  const { projectId } = useParams();
  const [queryParams] = useSearchParams();

  const { getAccessTokenSilently, user, userPerms } = useAuth();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isCreateReleaseModalOpen, setIsCreateReleaseModalOpen] =
    React.useState(false);
  const [showReleaseCompleteMessage, setShowReleaseCompleteMessage] =
    React.useState(false);

  const {
    data: projectDetails,
    isFetching: isProjectDetailsFetching,
    isError: isProjectDetailsError,
    error: projectDetailsError,
    refetch: refetchProjectDetails,
  } = useQuery(
    ['project-details', projectId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await projectService.getProjectDetails(
        accessToken,
        Number(projectId),
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  );

  const {
    data: projectRoles,
    isFetching: isProjectRolesFetching,
    isError: isProjectRolesError,
    error: projectRolesError,
    refetch: refetchProjectRoles,
  } = useQuery(
    'roles',
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await miscService.getRoles(accessToken);
    },
    {
      staleTime: 1000 * 60 * 5,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: projectActivityLogPreview,
    isFetching: isProjectActivityLogPreviewFetching,
    isError: isProjectActivityLogPreviewError,
    error: projectActivityLogPreviewError,
    refetch: refetchProjectActivityLogPreview,
  } = useQuery(
    ['activity-log-preview', projectId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await miscService.getLogs(accessToken, Number(projectId), 6);
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  );

  const currentVersion = React.useMemo(
    () =>
      projectDetails?.versions?.filter(
        (version) => version.status.code !== ReleaseStatusType.Released,
      ),
    [projectDetails],
  );

  const releasedVersions = React.useMemo(
    () =>
      projectDetails?.versions?.filter(
        (version) => version.status.code === ReleaseStatusType.Released,
      ),
    [projectDetails],
  );

  const currentUserRole = React.useMemo(() => {
    if (userPerms?.includes(AppRole.Admin)) {
      return ProjectRole.Admin;
    } else {
      return projectDetails?.users?.find((usr) => usr.user?.id === user?.sub)
        ?.role;
    }
  }, [projectDetails, user, userPerms]);

  const projectUserData = React.useMemo(() => {
    if (projectDetails && projectRoles) {
      return sortUserData(projectDetails.users?.map((usr) => ({
        user: usr.user,
        role: projectRoles.find((role) => role.id === usr.role),
      })) ?? [], user?.sub ?? '');
    }
  }, [projectDetails, projectRoles, user]);

  const handleCreateReleaseModalClose = (created: boolean) => {
    setIsCreateReleaseModalOpen(false);
    if (created) {
      refetchProjectDetails();
      refetchProjectActivityLogPreview();
    }
  };

  React.useEffect(() => {
    const val = queryParams.get('statusChanged');
    const show =
      val === 'true' &&
      currentVersion?.[0].status.code ===
        ReleaseStatusType.ClientReviewPostTranslation;

    setShowReleaseCompleteMessage(show);
  }, [queryParams, currentVersion]);

  const canEditTranslationsInClientReview = React.useMemo(() => {
    return (
      userPerms?.includes(AppRole.Admin) ||
      currentUserRole === ProjectRole.Admin ||
      currentUserRole === ProjectRole.TranslationCoordinator
    );
  }, [userPerms, currentUserRole]);

  return (
    <>
      {projectDetails && (
        <CreateReleaseModal
          isOpen={isCreateReleaseModalOpen}
          onClose={handleCreateReleaseModalClose}
          projectId={projectDetails.id}
        />
      )}

      <LoadingMessage
        isLoading={
          isProjectDetailsFetching ||
          isProjectRolesFetching ||
          isProjectActivityLogPreviewFetching
        }
      />
      <ErrorMessagePopup
        isError={isProjectDetailsError}
        error={projectDetailsError}
        retry={refetchProjectDetails}
      />
      <ErrorMessagePopup
        isError={isProjectRolesError}
        error={projectRolesError}
        retry={refetchProjectRoles}
      />
      <ErrorMessagePopup
        isError={isProjectActivityLogPreviewError}
        error={projectActivityLogPreviewError}
        retry={refetchProjectActivityLogPreview}
      />
      <Link
        startDecorator={<FontAwesomeIcon icon={faAngleLeft} />}
        fontSize={'18px'}
        component={RouterLink}
        to={Page.Dashboard}
      >
        {t('project-details.back-link')}
      </Link>
      <Skeleton loading={isProjectDetailsFetching} variant='inline'>
        <Heading style={{ margin: '8px 0px 24px' }}>
          {projectDetails?.name ?? '--'}
        </Heading>
      </Skeleton>
      <div
        style={{
          display: 'flex',
          gap: '48px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            width: 'calc(75% - 48px)',
          }}
        >
          {showReleaseCompleteMessage && (
            <div style={{ marginBottom: '16px' }}>
              <Message
                variant='success'
                title={t('project-details.release-complete.heading')}
                onClose={() => setShowReleaseCompleteMessage(false)}
              >
                <div>
                  <Typography sx={{ color: 'inherit' }}>
                    {t('project-details.release-complete.body')}
                    {!canEditTranslationsInClientReview &&
                      ` ${t('project-details.release-complete.no-changes')}`}
                  </Typography>
                </div>
              </Message>
            </div>
          )}
          <div>
            <Subtitle style={{ fontSize: '16px', marginBottom: '16px' }}>
              {t('project-details.current')}
            </Subtitle>
            {isProjectDetailsFetching ? (
              <div>
                <Skeleton
                  width={'100%'}
                  height={'81px'}
                  loading={isProjectDetailsFetching}
                  sx={{ position: 'relative' }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                {currentVersion?.map((version) => (
                  <ProjectVersion
                    key={version.id}
                    version={version}
                    projectId={projectDetails?.id as number}
                  />
                ))}
              </div>
            )}
            {currentVersion?.length === 0 && (
              <div
                style={{
                  color: theme.colour.grey,
                  textAlign: 'center',
                  margin: '24px 0',
                }}
              >
                <FontAwesomeIcon
                  icon={faFileCircleQuestion}
                  color='inherit'
                  size='3x'
                />
                <Typography sx={{ color: 'inherit', margin: '16px 0' }}>
                  {t('project-details.no-current.no-upcoming')}{' '}
                  <Typography
                    variant='outlined'
                    sx={{
                      padding: '6px',
                      borderRadius: '8px',
                      color: 'inherit',
                    }}
                    fontWeight={600}
                  >
                    {projectDetails?.name}
                  </Typography>
                </Typography>
                {(currentUserRole === ProjectRole.ProjectManager ||
                  currentUserRole === ProjectRole.Admin) && (
                  <Typography sx={{ color: 'inherit' }}>
                    {t('project-details.no-current.new-release-instruction')}{' '}
                    <Typography
                      variant='outlined'
                      sx={{
                        padding: '6px',
                        borderRadius: '8px',
                        color: 'inherit',
                      }}
                      fontWeight={600}
                    >
                      +
                      {` ${t('project-details.no-current.new-release-button')}`}
                    </Typography>{' '}
                    {t('project-details.no-current.above')}
                  </Typography>
                )}
              </div>
            )}
          </div>
          <div>
            <Subtitle style={{ fontSize: '16px', marginBottom: '16px' }}>
              {t('project-details.released')}
            </Subtitle>
            {isProjectDetailsFetching ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                {[...Array(3)].map((_, index) => (
                  <Skeleton
                    key={`loader-${index}`}
                    width={'100%'}
                    height={'81px'}
                    loading={isProjectDetailsFetching}
                    sx={{ position: 'relative' }}
                  />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                {releasedVersions?.map((version) => (
                  <ProjectVersion
                    key={version.id}
                    version={version}
                    projectId={projectDetails?.id as number}
                  />
                ))}
                {releasedVersions?.length === 0 && (
                  <div
                    style={{
                      color: theme.colour.grey,
                      textAlign: 'center',
                      margin: '24px 0',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faFileCircleQuestion}
                      color='inherit'
                      size='3x'
                    />
                    <Typography sx={{ color: 'inherit', margin: '16px 0' }}>
                      {t('project-details.no-released.no-versions')}{' '}
                      <Typography
                        variant='outlined'
                        sx={{
                          padding: '6px',
                          borderRadius: '8px',
                          color: 'inherit',
                        }}
                        fontWeight={600}
                      >
                        {projectDetails?.name}
                      </Typography>{' '}
                      {t('project-details.no-released.been-relsd')}
                    </Typography>
                    <Typography sx={{ color: 'inherit' }}>
                      {t('project-details.no-released.listed')}
                    </Typography>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            width: '25%',
            minWidth: '250px',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '16px',
              maxWidth: '100%',
              flexWrap: 'wrap',
            }}
          >
            {(currentUserRole === ProjectRole.ProjectManager ||
              currentUserRole === ProjectRole.Admin) &&
              currentVersion?.length === 0 && (
                <Button
                  startDecorator={<FontAwesomeIcon icon={faPlus} />}
                  onClick={() => setIsCreateReleaseModalOpen(true)}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {t('project-details.create-release')}
                </Button>
              )}
            <Button
              startDecorator={<FontAwesomeIcon icon={faFileLines} />}
              sx={{ whiteSpace: 'nowrap' }}
              onClick={() => navigate(`${Page.ProjectRoute}/${projectId}/${Page.ReviewTranslations}`)}
            >
              {t('project-details.view-all')}
            </Button>
            {currentUserRole === ProjectRole.Admin && (
              <Button
                startDecorator={<FontAwesomeIcon icon={faPencil} />}
                sx={{ whiteSpace: 'nowrap' }}
                onClick={() =>
                  navigate(
                    `${Page.ProjectRoute}/${projectId}/${Page.EditProject}`,
                  )
                }
              >
                {t('project-details.edit')}
              </Button>
            )}
          </div>
          <ProjectTeam projectUserData={projectUserData} />
          <ActivityLogPreview
            logData={projectActivityLogPreview}
            projectId={Number(projectId)}
            projectName={projectDetails?.name}
            userProjectRole={currentUserRole}
          />
          <LanguageList
            langs={projectDetails?.langs}
            defaultLang={projectDetails?.defaultLang}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectDetails;
