import React from 'react';
import Privacy from './privacy';
import { css } from 'aphrodite';
import layoutStyles from '../../components/layout/layout.styles';

const PrivacyPublic = () => {
  return (
    <div className={css(layoutStyles.contentContainer)}>
      <Privacy isPublic />
    </div>
  );
};

export default PrivacyPublic;
