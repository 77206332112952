import React from 'react';
import Message from '../message/message';
import axios from 'axios';
import { Link } from '@mui/joy';
import { useTranslation } from 'react-i18next';

export type GitValidationErrorMessageProps = {
  error: unknown;
  retry?: () => void;
};

const GitValidationErrorMessage = ({
  error,
  retry,
}: GitValidationErrorMessageProps) => {
  const { t } = useTranslation();
  const { isValidationError, apiErrorMessage, prompt } = React.useMemo(() => {
    if (axios.isAxiosError(error)) {
      const errorCode = error.response?.data?.error?.code;
      return {
        isValidationError:
          !!errorCode &&
          ['ERR_GITHUB_NOREPO', 'ERR_GITHUB_PAT', 'ERR_GITHUB_PERMS', 'ERR_GITHUB_WSCPE'].includes(
            errorCode,
          ),
        apiErrorMessage:
          error.response?.data?.error?.message || t('errors.unknown-error'),
        prompt:
          errorCode === 'ERR_GITHUB_NOREPO'
            ? t('create-project.errors.git-api.no-repo')
            : errorCode === 'ERR_GITHUB_PAT'
              ? t('create-project.errors.git-api.pat')
              : errorCode === 'ERR_GITHUB_PERMS'
                ? t('create-project.errors.git-api.perms')
                : errorCode === 'ERR_GITHUB_WSCPE'
                  ? t('create-project.errors.git-api.write-scope')
                  : t('errors.try-again'),
      };
    } else {
      return {
        isValidationError: false,
        apiErrorMessage: t('errors.unknown-error'),
      };
    }
  }, [error, t]);

  return (
    <Message
      variant='danger'
      title={
        isValidationError
          ? t('create-project.errors.git-api.invalid')
          : t('errors.general')
      }
      style={{ fontSize: '16px' }}
    >
      {isValidationError ? (
        <>
          <div>{apiErrorMessage}</div>
          <div>{prompt}</div>
        </>
      ) : (
        <>
          <div>{t('errors.unknown-error')}</div>
          {retry && (
            <Link component='a' onClick={() => retry()} color='danger'>
              {t('errors.try-again-click')}
            </Link>
          )}
        </>
      )}
    </Message>
  );
};

export default GitValidationErrorMessage;
