export enum Page {
    Dashboard = '/app',
    CreateProject = '/app/project/create',
    EditProject = 'edit',
    ProjectRoute = '/app/project',
    Keys = 'keys',
    Translate = 'translate',
    ReviewTranslations = 'translations',
    Privacy = '/app/privacy',
    PublicPrivacy = '/privacy',
    Landing = '/'
}