import { Button, Link, Typography, useTheme } from '@mui/joy';
import React from 'react';
import theme from '../../styles/theme';
import { Link as RouterLink } from 'react-router-dom';
import { Page } from '../../types/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightLong,
  faClipboardCheck,
  faCubes,
  faRobot,
  faShieldHalved,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { css } from 'aphrodite';
import landingPageStyles from './landing.styles';
import Card from '../../components/card/card';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

type FeatureCardProps = {
  img: React.ReactNode;
  title: string;
  description: string;
  learnMore?: () => void;
};

const FeatureCard = ({
  img,
  title,
  description,
  learnMore,
}: FeatureCardProps) => {
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        padding: '24px 36px',
        maxWidth: '300px',
        ':hover': {
          transform: 'scale(1.05)',
        },
        transition: 'all 0.5s ease',
        height: '324px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ marginBottom: '16px' }}>{img}</div>
        <div style={{ marginBottom: '24px' }}>
          <Typography level='h2'>{title}</Typography>
        </div>
        <Typography>{description}</Typography>
        {learnMore ? (
          <Link
            color='neutral'
            sx={{
              fontWeight: 600,
              marginTop: '16px',
              ':hover': {
                '::after': {
                  content: "' →'",
                },
              },
            }}
            component='a'
            onClick={learnMore}
          >
            {t('landing.learn-more')}&nbsp;
          </Link>
        ) : (
          <div />
        )}
      </div>
    </Card>
  );
};

type DetailButtonProps = {
  number: number;
  title: string;
  selected: boolean;
  onClick: () => void;
};

const DetailButton = ({
  number,
  title,
  selected,
  onClick,
}: DetailButtonProps) => {
  return (
    <Button
      color={selected ? 'primary' : 'neutral'}
      variant={selected ? 'solid' : 'soft'}
      size='lg'
      sx={{
        fontSize: '24px',
        padding: '8px 48px',
        width: '350px',
        transform: selected ? 'scale(1.05)' : undefined,
        transition: 'all 0.5s ease',
      }}
      onClick={onClick}
    >
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <div style={{ fontWeight: 900, fontSize: '48px' }}>{number}</div>
        <div style={{ flexGrow: 1 }}>{title}</div>
      </div>
    </Button>
  );
};

type DetailsSectionProps = {
  selection: number | undefined;
};

type DetailContent = {
  videoSrc: string;
  fallbackImg: string;
  fallbackImgAlt: string;
  captionHeading: string;
  captionSubheading: string;
}

const getDetailContent = (selection: number): DetailContent => {
  switch(selection) {
    case 2: {
      return {
        videoSrc: '/media/OT_LandingDemo_Translate.mp4',
        fallbackImg: '/media/OT_LandingDemo_Translate_Fallback.png',
        fallbackImgAlt: 'landing.detail-content.translate.img-alt',
        captionHeading: 'landing.detail-content.translate.heading',
        captionSubheading: 'landing.detail-content.translate.subheading'
      }
    }
    case 3: {
      return {
        videoSrc: '/media/OT_LandingDemo_Review.mp4',
        fallbackImg: '/media/OT_LandingDemo_Review_Fallback.png',
        fallbackImgAlt: 'landing.detail-content.review.img-alt',
        captionHeading: 'landing.detail-content.review.heading',
        captionSubheading: 'landing.detail-content.review.subheading'
      }
    }
    case 4: {
      return {
        videoSrc: '/media/OT_LandingDemo_Release.mp4',
        fallbackImg: '/media/OT_LandingDemo_Release_Fallback.png',
        fallbackImgAlt: 'landing.detail-content.release.img-alt',
        captionHeading: 'landing.detail-content.release.heading',
        captionSubheading: 'landing.detail-content.release.subheading'
      }
    }
    case 5: {
      return {
        videoSrc: '/media/OT_LandingDemo_Integrate.mp4',
        fallbackImg: '/media/OT_LandingDemo_Integrate_Fallback.png',
        fallbackImgAlt: 'landing.detail-content.integrate.img-alt',
        captionHeading: 'landing.detail-content.integrate.heading',
        captionSubheading: 'landing.detail-content.integrate.subheading'
      }
    }
    default: {
      return {
        videoSrc: '/media/OT_LandingDemo_AddKeys.mp4',
        fallbackImg: '/media/OT_LandingDemo_AddKeys_Fallback.png',
        fallbackImgAlt: 'landing.detail-content.add-keys.img-alt',
        captionHeading: 'landing.detail-content.add-keys.heading',
        captionSubheading: 'landing.detail-content.add-keys.subheading'
      }
    }
  }
}

const DetailsSection = ({ selection }: DetailsSectionProps) => {
  const joyUiTheme = useTheme();
  const { t } = useTranslation();
  const [bgColour, setBgColour] = React.useState(
    joyUiTheme.colorSchemes.light.palette.primary[700],
  );
  const [selected, setSelected] = React.useState<number>(1);

  React.useEffect(() => {
    if (selection) {
      setSelected(selection);
    }
  }, [selection]);

  const detailContent = React.useMemo(() => getDetailContent(selected), [selected]);

  return (
    <div
      style={{
        height: '500px',
        background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.25) 100%), ${bgColour}`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        transition: 'all 0.5s ease',
        padding: '96px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '96px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <DetailButton
            number={1}
            title={t('landing.detail-content.add-keys.title')}
            selected={selected === 1}
            onClick={() => {
              setBgColour(joyUiTheme.colorSchemes.light.palette.primary[700]);
              setSelected(1);
            }}
          />
          <DetailButton
            number={2}
            title={t('landing.detail-content.translate.title')}
            selected={selected === 2}
            onClick={() => {
              setBgColour(joyUiTheme.colorSchemes.light.palette.warning[400]);
              setSelected(2);
            }}
          />
          <DetailButton
            number={3}
            title={t('landing.detail-content.review.title')}
            selected={selected === 3}
            onClick={() => {
              setBgColour(joyUiTheme.colorSchemes.light.palette.danger[600]);
              setSelected(3);
            }}
          />
          <DetailButton
            number={4}
            title={t('landing.detail-content.release.title')}
            selected={selected === 4}
            onClick={() => {
              setBgColour(joyUiTheme.colorSchemes.light.palette.success[500]);
              setSelected(4);
            }}
          />
          <DetailButton
            number={5}
            title={t('landing.detail-content.integrate.title')}
            selected={selected === 5}
            onClick={() => {
              setBgColour(joyUiTheme.colorSchemes.light.palette.neutral[500]);
              setSelected(5);
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <div style={{ width: '38vw', height: '100%' }}>
            <div
              style={{
                borderRadius: '24px',
                display: 'flex',
                overflow: 'hidden',
                boxShadow: '24px 24px 90px 0px rgba(0,0,0,0.5)',
              }}
            >
              <video
                autoPlay
                loop
                muted
                playsInline
                disablePictureInPicture
                controlsList='nodownload'
                width={'100%'}
                height={'100%'}
                key={detailContent.videoSrc}
              >
                <source
                  src={detailContent.videoSrc}
                  type='video/mp4'
                />
                <img src={detailContent.fallbackImg} alt={t(detailContent.fallbackImgAlt)} />
              </video>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <Card sx={{ boxShadow: '12px 12px 24px 0px rgba(0,0,0,0.3)', textAlign: 'center', transition: 'all 0.5s ease' }}>
              <>
                <div style={{ fontSize: '20px', fontWeight: 600 }}>
                  {t(detailContent.captionHeading)}
                </div>
                <div style={{ fontSize: '16px', fontWeight: 400 }}>
                {t(detailContent.captionSubheading)}
                </div>
              </>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

const LandingPage = () => {
  const featureSectionRef = React.useRef<HTMLDivElement | null>(null);
  const detailSectionRef = React.useRef<HTMLDivElement | null>(null);
  const joyUiTheme = useTheme();
  const { t } = useTranslation();
  const [overrideDetailSelection, setOverrideDetailSelection] = React.useState<
    number | undefined
  >(undefined);

  return (
    <>
      <div
        style={{
          background:
            'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.65) 100%), url(/img/ot_landing_bg.jpg) top/cover fixed',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          height: '100vh',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              color: theme.colour.white,
              fontWeight: 800,
              fontSize: '96px',
              textShadow: '#000 8px 8px 32px',
            }}
          >
            {t('landing.title-one')}
          </Typography>
          <Typography
            sx={{
              color: theme.colour.white,
              fontWeight: 800,
              fontSize: '56px',
              textShadow: '#000 8px 8px 32px',
            }}
          >
            {t('landing.title-two')}
          </Typography>
          <Typography
            sx={{
              color: theme.colour.white,
              fontWeight: 500,
              fontSize: '24px',
              textShadow: '#000 4px 4px 8px',
              margin: '32px 0',
            }}
          >
            {t('landing.subheading')}
          </Typography>
          <div style={{ display: 'flex', gap: '36px' }}>
            <Button
              size='lg'
              className={css(landingPageStyles.largeButton)}
              endDecorator={<FontAwesomeIcon icon={faArrowRightLong} />}
              color='primary'
              variant='soft'
              onClick={() =>
                featureSectionRef?.current?.scrollIntoView({
                  behavior: 'smooth',
                })
              }
            >
              {t('landing.learn-more')}
            </Button>
            <RouterLink to={Page.Dashboard}>
              <Button
                size='lg'
                className={css(landingPageStyles.largeButton)}
                endDecorator={<FontAwesomeIcon icon={faArrowRightLong} />}
                color='primary'
                variant='solid'
              >
                {t('landing.get-started')}
              </Button>
            </RouterLink>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: theme.colour.nearBlack,
          padding: '64px 96px 96px',
          display: 'flex',
          flexDirection: 'column',
          gap: '48px',
        }}
        ref={featureSectionRef}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <Typography
              sx={{ color: theme.colour.white, fontSize: '28px' }}
              level='h4'
            >
              {t('landing.summary-one')}
            </Typography>
            <Typography
              sx={{ color: theme.colour.white, fontSize: '20px' }}
              level='h4'
            >
              {t('landing.summary-two')}
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <FeatureCard
            img={
              <FontAwesomeIcon
                icon={faUsers}
                size='3x'
                color={joyUiTheme.colorSchemes.light.palette.primary[700]}
              />
            }
            title={t('landing.features.collaboration.title')}
            description={t('landing.features.collaboration.description')}
          />
          <FeatureCard
            img={<FontAwesomeIcon icon={faGithub} size='3x' />}
            title={t('landing.features.integration.title')}
            description={t('landing.features.integration.description')}
            learnMore={() => {
              setOverrideDetailSelection(4);
              detailSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
            }}
          />
          <FeatureCard
            img={
              <FontAwesomeIcon
                icon={faRobot}
                size='3x'
                color={joyUiTheme.colorSchemes.light.palette.warning[400]}
              />
            }
            title={t('landing.features.automation.title')}
            description={t('landing.features.automation.description')}
            learnMore={() => {
              setOverrideDetailSelection(2);
              detailSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <FeatureCard
            img={
              <FontAwesomeIcon
                icon={faShieldHalved}
                size='3x'
                color={joyUiTheme.colorSchemes.light.palette.danger[600]}
              />
            }
            title={t('landing.features.access-control.title')}
            description={t('landing.features.access-control.description')}
          />
          <FeatureCard
            img={
              <FontAwesomeIcon
                icon={faClipboardCheck}
                size='3x'
                color={joyUiTheme.colorSchemes.light.palette.success[500]}
              />
            }
            title={t('landing.features.accuracy.title')}
            description={t('landing.features.accuracy.description')}
            learnMore={() => {
              setOverrideDetailSelection(3);
              detailSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
            }}
          />
          <FeatureCard
            img={
              <FontAwesomeIcon
                icon={faCubes}
                size='3x'
                color={joyUiTheme.colorSchemes.light.palette.neutral[500]}
              />
            }
            title={t('landing.features.simple-to-use.title')}
            description={t('landing.features.simple-to-use.description')}
          />
        </div>
      </div>
      <div ref={detailSectionRef}>
        <DetailsSection selection={overrideDetailSelection} />
      </div>
    </>
  );
};

export default LandingPage;
