import React from 'react';
import { User } from '../../types/user';
import { User as Auth0User } from '@auth0/auth0-react';
import { css } from 'aphrodite';
import profileNameStyles from './profile-name.styles';
import useAuth from '../../util/auth-hook';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Badge,
  Typography,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import commonStyles from '../../styles/common.styles';

export type ProfileNameProps = {
  user?: User;
  variant?: 'large' | 'small' | 'xsmall';
  tagline?: string | React.ReactNode;
  timeline?: string;
  badge?: React.ReactNode;
  badgeColour?: 'success' | 'danger' | 'warning' | 'primary' | 'neutral';
  dropdownContent?: React.ReactNode;
};

type ProfileNameContentProps = ProfileNameProps & {
  authenticatedUser?: Auth0User;
};

const ProfileNameContent = ({
  variant,
  tagline,
  user,
  authenticatedUser,
}: ProfileNameContentProps) => {
  const { t } = useTranslation();
  return (
    <div style={{ overflow: 'hidden' }}>
      <Typography
        noWrap
        fontSize={variant === 'xsmall' ? '14px' : tagline ? '16px' : '18px'}
        fontWeight={500}
      >
        {user?.name.full ?? t('profile-name.unknown')}
        {authenticatedUser?.sub === user?.id && ` ${t('profile-name.you')}`}
      </Typography>
      {tagline && (
        <div className={css(profileNameStyles.tagline)}>{tagline}</div>
      )}
    </div>
  );
};

const ProfileName = ({
  user,
  variant = 'small',
  tagline,
  timeline,
  badge,
  badgeColour,
  dropdownContent,
}: ProfileNameProps) => {
  const { user: authenticatedUser } = useAuth();

  return (
    <div
      className={css(
        profileNameStyles.container,
        dropdownContent ? undefined : commonStyles.centreAlign,
      )}
      data-testid={`profile-name-${user?.id ?? 'unknown'}`}
    >
      <div
        className={css(profileNameStyles.nameAndProfileGroup, dropdownContent ? undefined : commonStyles.centreAlign)}
        style={{
          gap: variant === 'large' ? '16px' : '8px',
        }}
      >
        <div
          className={css(
            profileNameStyles[
              variant === 'xsmall' ? 'iconContainerSmall' : 'iconContainer'
            ],
            dropdownContent ? undefined : commonStyles.centreAlign,
          )}
        >
          {badge ? (
            <div>
              <Badge
                badgeContent={badge}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeInset={'15%'}
                color={badgeColour}
                size='md'
                sx={{ '--Badge-paddingX': 0 }}
              >
                <Avatar
                  variant='solid'
                  src={user?.picture}
                  alt={user?.name.full}
                  sx={
                    variant === 'xsmall'
                      ? { width: '36px', height: '36px' }
                      : undefined
                  }
                />
              </Badge>
            </div>
          ) : (
            <Avatar
              variant='solid'
              src={user?.picture}
              alt={user?.name.full}
              sx={
                variant === 'xsmall'
                  ? { width: '36px', height: '36px' }
                  : undefined
              }
            />
          )}
        </div>
        {dropdownContent ? (
          <div style={{ width: '100%' }}>
            <Accordion>
              <AccordionSummary>
                <ProfileNameContent
                  user={user}
                  variant={variant}
                  tagline={tagline}
                  authenticatedUser={authenticatedUser}
                />
              </AccordionSummary>
              <AccordionDetails>{dropdownContent}</AccordionDetails>
            </Accordion>
          </div>
        ) : (
          <ProfileNameContent
            user={user}
            variant={variant}
            tagline={tagline}
            authenticatedUser={authenticatedUser}
          />
        )}
      </div>
      <div className={css(profileNameStyles.timeline)}>{timeline ?? ''}</div>
    </div>
  );
};

export default ProfileName;
