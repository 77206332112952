import Card from '../../card/card';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionLog } from '../../../types/misc';
import { processActivityLogData } from '../activity-log.helper';
import ActivityLog from '../activity-log';
import { Button } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import ActivityLogModal from '../activity-log-modal/activity-log-modal';
import { ProjectRole } from '../../../types/user';

export type ActivityLogPreviewProps = {
  logData?: ActionLog[];
  projectId: number;
  projectName?: string;
  userProjectRole?: ProjectRole;
};

const ActivityLogPreview = ({
  logData,
  projectId,
  projectName,
  userProjectRole,
}: ActivityLogPreviewProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { t, i18n } = useTranslation();
  const processedLogData = React.useMemo(
    () => processActivityLogData(logData),
    [logData, i18n.language],
  );
  return (
    <>
      <ActivityLogModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        projectId={projectId}
        projectName={projectName}
        userProjectRole={userProjectRole}
      />
      <Card sx={{ padding: '24px' }}>
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '8px',
            }}
          >
            <h2 style={{ margin: 0 }}>{t('activity-log.title')}</h2>
            {processedLogData && processedLogData.length > 0 && (
              <Button
                startDecorator={<FontAwesomeIcon icon={faClockRotateLeft} />}
                onClick={() => setIsModalOpen(true)}
              >
                {t('activity-log.see-all')}
              </Button>
            )}
          </div>
          <ActivityLog processedData={processedLogData} showAdditionalInfo={false} />
        </>
      </Card>
    </>
  );
};

export default ActivityLogPreview;
