import { Language, LanguageData } from "./misc";
import { ProjectRole, User } from "./user";

export type Project = {
    id: number;
    name: string;
    defaultLang: string;
    langs: string[];
    repoName: string;
    repoOwner: string;
    pat: string;
    users: ProjectUser[];
}

export type ProjectUser = {
    userId: string;
    role: ProjectRole;
}

export type DetailedProjectUser = {
    role?: ProjectRole;
    user: User;
}

export type ProjectCreateInput = {
    name: string;
    defaultLang: string;
    langs: string[];
    repoName: string;
    repoOwner: string;
    pat: string;
    users: ProjectUser[];
}

export type UpdateProjectDetailsInput = {
    name?: string;
    pat?: string;
    users?: ProjectUser[];
}

export type ProjectDetails = {
    id: number;
    name: string;
    defaultLang: LanguageData;
    langs: LanguageData[];
    users?: DetailedProjectUser[];
    versions?: ProjectVersionWithActions[];
    repoName?: string;
    repoOwner?: string;
}

export type ProjectVersionWithActions = {
    id: number;
    name: string;
    status: ProjectReleaseStatusData;
    scheduledReleaseDate: string;
    actualReleaseDate?: string;
    createdDate: string;
    userActions: PermittedUserActions[];
}

export type ProjectRelease = {
    id: number;
    projectId: number;
    versionName: string;
    createdDate: string;
    scheduledReleaseDate: string;
    actualReleaseDate?: string;
    status: ReleaseStatus;
    commitSha?: string;
    gitUrl?: string;
}

export type CreateProjectReleaseInput = {
    versionName: string;
    scheduledReleaseDate: string;
}

export type ProjectListResponse = {
    id: number;
    name: string;
    defaultLang: string;
    langs: Language[];
    latestVersion?: {
        versionId: number,
        versionName: string,
        releaseDate: string,
        status: ProjectReleaseStatusData,
    }
    userActions: PermittedUserActions[];
}

export type UpdateProjectResponse = {
    id: number;
    name: string;
    defaultLang: string;
    langs: string[];
    repoName: string;
    repoOwner: string;
    users?: DetailedProjectUser[];
}

export type BasicReleaseDetails = {
    id: number;
    versionName: string;
    projectName: string;
    userProjectRole?: ProjectRole;
    status: ProjectReleaseStatusData;
    githubUrl?: string;
}

export type ProjectReleaseStatusData = {
    code: ReleaseStatus;
    description: string;
    shortDescription?: string;
}

export enum ReleaseStatus {
    InDevelopment = 'INDEV',
    ClientReviewPreTranslation = 'CRPRE',
    Translating = 'TRANS',
    ClientReviewPostTranslation = 'CRPOS',
    Released = 'RELSD'
}

export enum PermittedUserActions {
    AddAndReviewKeys = 'ADDKEY',
    ReviewKeys = 'REVKEY',
    ReviewTranslations = 'REVTRN',
    Translate = 'TRANSL'
}