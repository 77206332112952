import { StyleSheet } from "aphrodite";

const commonStyles = StyleSheet.create({
    // used to reset styling of native HTML elements, such as <button />
    nativeReset: {
        padding: '0px',
        border: 'none',
        font: 'inherit',
        color: 'inherit',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        textDecoration: 'none',
        textAlign: 'left',
      },
      centreAlign: {
        alignItems: 'center'
      }
});

export default commonStyles;