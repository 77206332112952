import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenSizeContext } from '../../../util/screensize-context';
import theme from '../../../styles/theme';
import { Link, Link as RouterLink } from 'react-router-dom';
import { Page } from '../../../types/navigation';
import { Button } from '@mui/joy';

export type PublicNavBarProps = {
  isStatic?: boolean;
};

const PublicNavBar = ({ isStatic }: PublicNavBarProps) => {
  const { t } = useTranslation();
  const { isScreenScrolled } = useScreenSizeContext();

  const isScrolled = React.useMemo(
    () => (isStatic ? true : isScreenScrolled),
    [isScreenScrolled, isStatic],
  );

  return (
    <div
      style={{
        width: isScrolled ? 'calc(100% - 72px)' : 'calc(100% - 96px)',
        height: isScrolled ? '54px' : '72px',
        position: isStatic ? 'relative' : 'fixed',
        padding: isScrolled ? '36px' : '48px',
        backgroundColor: isScrolled ? theme.colour.primary : 'transparent',
        transition: 'all 0.5s ease',
        zIndex: 20,
        top: 0,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div>
          <Link to={Page.Landing}>
            <img
              src='/img/OneTranslateLogo.svg'
              height={'100%'}
              alt={`OneTranslate ${t('nav.logo')}`}
            />
          </Link>
        </div>
        <div>
          <RouterLink to={Page.Dashboard}>
            <Button
              variant='soft'
              color='neutral'
              size={'lg'}
              sx={isScrolled ? undefined : { boxShadow: '12px 12px 45px 0px rgba(0,0,0,0.75)' }}
            >
              {t('nav.login')}
            </Button>
          </RouterLink>
        </div>
      </div>
    </div>
  );
};

export default PublicNavBar;
