import {
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  Tooltip,
} from '@mui/joy';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useAuth from '../../../util/auth-hook';
import miscService from '../../../service/misc';
import {
  processActivityLogData,
  ProcessedActivityLogData,
} from '../activity-log.helper';
import ActivityLog from '../activity-log';
import ErrorMessage from '../../error-message/error-message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateBack } from '@fortawesome/free-solid-svg-icons';
import { ProjectRole } from '../../../types/user';

export type ActivityLogModalProps = {
  isOpen: boolean;
  onClose: () => void;
  projectId: number;
  projectName?: string;
  userProjectRole?: ProjectRole;
};

const ActivityLogModal = ({
  isOpen,
  onClose,
  projectId,
  projectName,
  userProjectRole,
}: ActivityLogModalProps) => {
  const [currentOffset, setCurrentOffset] = React.useState(0);
  const [logs, setLogs] = React.useState<ProcessedActivityLogData[]>([]);

  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth();

  const {
    data: lastProjectActivityLogs,
    isFetching: isProjectActivityLogsFetching,
    isError: isProjectActivityLogsError,
    error: projectActivityLogsError,
    refetch: refetchProjectActivityLogs,
  } = useQuery(
    ['activity-logs', projectId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await miscService.getLogs(
        accessToken,
        Number(projectId),
        10,
        currentOffset,
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      onSuccess: (newLogs) => {
        const processedNewLogs = processActivityLogData(newLogs) ?? [];
        if (currentOffset === 0) {
          setLogs(processedNewLogs);
        } else {
          setLogs((existingLogs) =>
            [...existingLogs, ...processedNewLogs]
              .filter(
                (log, index, self) =>
                  index === self.findIndex((lg) => lg.id === log.id),
              )
              .sort((a, b) => b.id - a.id),
          );
        }
      },
    },
  );

  React.useEffect(() => {
    refetchProjectActivityLogs();
  }, [currentOffset, refetchProjectActivityLogs]);

  const handleRefresh = () => {
    if (currentOffset === 0) {
      refetchProjectActivityLogs();
    } else {
      setCurrentOffset(0);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalDialog maxWidth={'750px'} minWidth={'750px'}>
        <DialogTitle>
          {t('activity-log.title')}
          {projectName && `: ${projectName}`}
        </DialogTitle>
        <ModalClose />
        <DialogContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '16px'
            }}
          >
            <div>
              <Trans
                i18nKey={'activity-log.intro'}
                components={{ b: <b /> }}
                values={{ projectName }}
              />
              {userProjectRole === ProjectRole.Admin &&
                ` ${t('activity-log.intro-admin')}`}
            </div>
            <div>
              <Tooltip title={t('common.refresh')} arrow placement='top'>
                <IconButton
                  onClick={handleRefresh}
                  variant='outlined'
                  color='primary'
                  loading={isProjectActivityLogsFetching}
                >
                  <FontAwesomeIcon icon={faArrowRotateBack} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div style={{ padding: '24px 16px', overflow: 'auto' }}>
            <ActivityLog
              processedData={logs}
              showAdditionalInfo={userProjectRole === ProjectRole.Admin}
            />
          </div>
          {logs.length >= 10 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '16px 0',
              }}
            >
              <Button
                onClick={() => setCurrentOffset((offst) => offst + 10)}
                variant='outlined'
                color='primary'
                disabled={lastProjectActivityLogs?.length === 0}
                loading={isProjectActivityLogsFetching}
              >
                {t('activity-log.show-more')}
              </Button>
            </div>
          )}

          <ErrorMessage
            isError={isProjectActivityLogsError}
            error={projectActivityLogsError}
            retry={refetchProjectActivityLogs}
          />
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default ActivityLogModal;
