import { TranslationValueData } from "../../types/translation";
import * as Yup from 'yup';
import i18n from "../../util/i18n";

export type TranslationFieldData = {
  key: string;
  primaryValue: string;
  context?: string;
  translatedValue?: string;
  autoTranslatedValue?: string;
  placeholder: boolean;
  edited: boolean;
};

export type TranslationFormValues = {
  translation: TranslationFieldData[];
};

export const mapFormValuesToApiData = (
    formValues: TranslationFormValues,
  ): TranslationValueData[] => {
    return formValues.translation.filter((formData) => formData.edited === true).map((formData) => ({
      key: formData.key,
      translatedValue: formData.translatedValue || undefined,
      autoTranslated: !!formData.autoTranslatedValue && formData.autoTranslatedValue === formData.translatedValue,
    }));
};

export const translateFormValidationSchema = Yup.object().shape({
translation: Yup.array()
    .of(
    Yup.object().shape({
        key: Yup.string().required(),
        primaryValue: Yup.string().required(),
        context: Yup.string().optional(),
        translatedValue: Yup.string()
        .optional()
        .max(2000, i18n.t('translate.error-length', { max: 2000 })),
        autoTranslatedValue: Yup.string().optional(),
        placeholder: Yup.boolean().required(),
        edited: Yup.boolean().required(),
    }),
    )
    .required(),
});