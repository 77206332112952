import { css } from 'aphrodite';
import React from 'react';
import footerStyles from './footer.styles';
import theme from '../../../styles/theme';
import { Link } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCookieBite,
  faLanguage,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons';
import I18nModal from '../../i18n-modal/i18n-modal';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Page } from '../../../types/navigation';
import { useCookieContext } from '../../../util/cookie-context';

export type FooterProps = {
  isPublic?: boolean;
}

const Footer = ({ isPublic }: FooterProps) => {
  const [isChangeLanguageModalOpen, setIsChangeLanguageModalOpen] =
    React.useState(false);

    const { t } = useTranslation();

    const { openCookiePreferencesModal } = useCookieContext();

  return (
    <>
      <I18nModal
        isOpen={isChangeLanguageModalOpen}
        onClose={() => setIsChangeLanguageModalOpen(false)}
      />
      <div className={css(footerStyles.container)}>
        <div
          style={{
            height: '100%',
            display: 'flex',
            width: '100%',
            gap: '32px',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              width: '30%'
            }}
          >
            <div style={{ height: '40%' }}>
              <img
                src='/img/OneTranslateLogo.svg'
                alt={`OneTranslate ${t('nav.logo')}`}
                height={'100%'}
              />
            </div>
            <div
              style={{
                color: theme.colour.background,
                fontWeight: 300,
                width: '75%',
                lineHeight: '1.5em',
              }}
            >
              {t('footer.tagline')}
            </div>
          </div>
          <div style={{ width: '40%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Link
                sx={{
                  color: theme.colour.background,
                  textDecorationColor: theme.colour.background,
                }}
                component={RouterLink}
                to={isPublic ? Page.PublicPrivacy : Page.Privacy}
                
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: '8px',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faUserShield}
                    color={theme.colour.background}
                    size='xl'
                  />
                  {t('footer.privacy')}
                </div>
              </Link>
              <Link
                sx={{
                  color: theme.colour.background,
                  textDecorationColor: theme.colour.background,
                }}
                component={'a'}
                onClick={() => openCookiePreferencesModal()}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: '8px',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCookieBite}
                    color={theme.colour.background}
                    size='xl'
                  />
                  {t('footer.cookie')}
                </div>
              </Link>
              <Link
                sx={{
                  color: theme.colour.background,
                  textDecorationColor: theme.colour.background,
                }}
                component='a'
                onClick={() => setIsChangeLanguageModalOpen(true)}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: '8px',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faLanguage}
                    color={theme.colour.background}
                    size='xl'
                  />
                  {t('footer.language')}
                </div>
              </Link>
            </div>
          </div>
          <div style={{ width: '30%' }}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: '14px',
              }}
            >
              <div
                style={{
                  color: theme.colour.background,
                  fontWeight: 300,
                  lineHeight: '1.5em',
                  textAlign: 'right',
                  width: '75%',
                  fontSize: '12px',
                }}
              >
                {t('footer.disclaimer')}
              </div>
              <div style={{ height: '30%', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <img
                  src='/img/UoB_Crest_Logo_RGB_NEG_Landscape.png'
                  alt={`University of Birmingham ${t('nav.logo')}`}
                  height={'100%'}
                />
              </div>
              <div style={{ color: theme.colour.background, fontSize: '14px', textAlign: 'right' }}>
                {t('footer.contact')}{' '}
                <Link
                  sx={{
                    color: 'inherit',
                    textDecorationColor: theme.colour.background,
                    fontSize: 'inherit',
                  }}
                  component='a'
                  href='mailto:beg118@student.bham.ac.uk'
                >
                  beg118@student.bham.ac.uk
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
