import { getEnvVariables } from "../util/env-variables"
import { ActionLog, AutoTranslationInput, LanguageData, TranslationResponse } from "../types/misc";
import { ProjectRoleData } from "../types/user";
import axiosClient from "../util/axios";

const miscService = {
    getLanguages: async (accessToken: string): Promise<LanguageData[]> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.get(`${apiRootUrl}/misc/languages`, { headers: { Authorization: `Bearer ${accessToken}` }});
    
            return response.data.data as LanguageData[];
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    getRoles: async (accessToken: string, assignable?: boolean): Promise<ProjectRoleData[]> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.get(`${apiRootUrl}/misc/roles${assignable ? '?assignable=true' : ''}`, { headers: { Authorization: `Bearer ${accessToken}`} });

            return response.data.data as ProjectRoleData[];
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    translateText: async (accessToken: string, input: AutoTranslationInput): Promise<TranslationResponse> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.post(`${apiRootUrl}/misc/translate`, input, { headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data.data as TranslationResponse;
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    downloadBulkCSVTemplate: async (accessToken: string): Promise<Buffer> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.get(`${apiRootUrl}/misc/download-csv-template`, { responseType: 'blob', headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data as Buffer;
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    getLogs: async (accessToken: string, projectId: number, limit?: number, offset?: number): Promise<ActionLog[]> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.get(`${apiRootUrl}/misc/activity/${projectId}`, { headers: { Authorization: `Bearer ${accessToken}` }, params: { limit, offset } });

            return response.data.data as ActionLog[];
        } catch (err) {
            return Promise.reject(err as Error);
        }
    }
};

export default miscService;