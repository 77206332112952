import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { getEnvVariables } from './util/env-variables';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CssVarsProvider, THEME_ID } from '@mui/joy/styles';
import { Experimental_CssVarsProvider as MaterialCssVarsProvider } from '@mui/material/styles';
import joyUiTheme from './styles/joyui-theme';
import { ScreenSizeProvider } from './util/screensize-context';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './util/i18n';
import { CookieContextProvider } from './util/cookie-context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const { authDomain, authClientId, authAudience, rootUrl } = getEnvVariables();

const queryClientProvider = new QueryClient();

root.render(
  <QueryClientProvider client={queryClientProvider}>
    <Auth0Provider
      domain={authDomain}
      clientId={authClientId}
      authorizationParams={{
        redirect_uri: `${rootUrl}/app`,
        audience: authAudience,
        ui_locales: 'en zh-CN zh-TW nl fr-FR de it ja pl pt-PT es tr',
      }}
      cacheLocation='localstorage'
    >
      <React.StrictMode>
        <CookieContextProvider>
          <ScreenSizeProvider>
            <MaterialCssVarsProvider>
              <CssVarsProvider theme={{ [THEME_ID]: joyUiTheme }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <App />
                </LocalizationProvider>
              </CssVarsProvider>
            </MaterialCssVarsProvider>
          </ScreenSizeProvider>
        </CookieContextProvider>
      </React.StrictMode>
    </Auth0Provider>
  </QueryClientProvider>,
);
