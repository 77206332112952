import React from 'react';
import {
  mapAdditionalInfoKey,
  mapLogAdditionalInfo,
  ProcessedActivityLogData,
} from './activity-log.helper';
import ProfileName from '../profile-name/profile-name';
import { Trans, useTranslation } from 'react-i18next';
import generalUtil from '../../util/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '../../styles/theme';
import { Skeleton } from '@mui/joy';
import { faZ } from '@fortawesome/free-solid-svg-icons';
import { Language } from '../../types/misc';

export type ActivityLogProps = {
  processedData?: ProcessedActivityLogData[];
  showAdditionalInfo?: boolean;
};

type LogAdditionalInfoProps = {
  date: string;
  additionalInfo?: Record<string, any>;
  langCode: Language;
};

const LogAdditionalInfo = ({
  date,
  additionalInfo,
  langCode,
}: LogAdditionalInfoProps) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        fontSize: '14px',
        marginTop: '8px',
      }}
    >
      <div>
        <b>{t('common.timestamp')}</b>:{' '}
        {generalUtil.formatDate(date, langCode, {
          weekday: 'short',
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        })}
      </div>
      {additionalInfo &&
        Object.entries(additionalInfo).map((info) => (
          <div key={info[0]}>
            <b>{t(mapAdditionalInfoKey(info[0]))}</b>:{' '}
            {Array.isArray(info[1]) ? info[1].join(', ') : info[1].toString()}
          </div>
        ))}
    </div>
  );
};

const ActivityLog = ({
  processedData,
  showAdditionalInfo,
}: ActivityLogProps) => {
  const { i18n, t } = useTranslation();
  return (
    <>
      {processedData ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          {processedData.length > 0 ? (
            processedData.map((log, index) => {
              const additionalInfo = mapLogAdditionalInfo(log.additionalInfo);
              return (
                <>
                  <ProfileName
                    user={log.user}
                    tagline={
                      <Trans
                        i18nKey={log.description}
                        values={additionalInfo}
                        components={{ b: <b /> }}
                      />
                    }
                    timeline={generalUtil.getTimestampRelativeToNow(
                      log.date,
                      i18n.language,
                      true,
                    )}
                    badge={
                      <FontAwesomeIcon icon={log.profileBadge.icon} size='xs' />
                    }
                    badgeColour={log.profileBadge.colour}
                    dropdownContent={
                      showAdditionalInfo ? (
                        <LogAdditionalInfo
                          date={log.date}
                          additionalInfo={additionalInfo}
                          langCode={i18n.language}
                        />
                      ) : undefined
                    }
                  />
                  {index !== processedData.length - 1 && (
                    <div>
                      <div
                        style={{
                          height: '20px',
                          width: '2px',
                          marginLeft: '20px',
                          backgroundColor: theme.colour.lightGrey,
                        }}
                      />
                    </div>
                  )}
                </>
              );
            })
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div>
                  <div style={{ marginLeft: '39px', marginBottom: '-8px' }}>
                    <FontAwesomeIcon
                      icon={faZ}
                      size='sm'
                      color={theme.colour.grey}
                      style={{ transform: 'rotate(-10deg)' }}
                    />
                  </div>
                  <div style={{ marginLeft: '24px', marginBottom: '-8px' }}>
                    <FontAwesomeIcon
                      icon={faZ}
                      size='1x'
                      color={theme.colour.grey}
                      style={{ transform: 'rotate(10deg)' }}
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faZ}
                      size='xl'
                      color={theme.colour.grey}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  fontSize: '18px',
                  color: theme.colour.grey,
                  fontWeight: 600,
                }}
              >
                {t('activity-log.no-activity.heading')}
              </div>
              <div style={{ color: theme.colour.grey, textAlign: 'center' }}>
                {t('activity-log.no-activity.body')}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Skeleton
            width={'100%'}
            height={'45px'}
            loading
            sx={{ position: 'relative' }}
          />
          <Skeleton
            width={'100%'}
            height={'45px'}
            loading
            sx={{ position: 'relative' }}
          />
          <Skeleton
            width={'100%'}
            height={'45px'}
            loading
            sx={{ position: 'relative' }}
          />
          <Skeleton
            width={'100%'}
            height={'45px'}
            loading
            sx={{ position: 'relative' }}
          />
        </div>
      )}
    </>
  );
};

export default ActivityLog;
