import React from 'react';
import Heading from '../../components/heading/heading';
import ProjectTranslationsTable from '../../components/project-translations-table/project-translations-table';
import { useParams, Link as RouterLink } from 'react-router-dom';
import Card from '../../components/card/card';
import Subtitle from '../../components/subtitle/subtitle';
import {
  Select,
  Skeleton,
  Option,
  Switch,
  Typography,
  Link,
  Button,
  Input,
  IconButton,
  FormControl,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { LanguageData } from '../../types/misc';
import { useMutation, useQuery } from 'react-query';
import useAuth from '../../util/auth-hook';
import projectService from '../../service/project';
import CountryFlag from '../../components/country-flag/country-flag';
import { useCookieContext } from '../../util/cookie-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faCircleInfo,
  faDownload,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import theme from '../../styles/theme';
import BasicReleaseDetails from '../../components/basic-release-details/basic-release-details';
import generalUtil from '../../util/general';
import ErrorMessagePopup from '../../components/error-message/error-message-popup/error-message-popup';
import { Page } from '../../types/navigation';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import gitService from '../../service/git';
import fileDownload from 'js-file-download';
import { ReleaseStatus } from '../../types/project';
import { Controller, useForm } from 'react-hook-form';

export enum FilterTarget {
  Key = 'key',
  Primary = 'primaryValue',
  Translated = 'translatedValue',
  Context = 'context',
}

export enum FilterType {
  Contains = 'contains',
  Starts = 'starts',
  Equals = 'equals',
}

export type FilterFormValues = {
  target: FilterTarget;
  type: FilterType;
  value?: string;
};

const ProjectTranslations = () => {
  const { projectId } = useParams();
  const { t, i18n } = useTranslation();
  const { getAccessTokenSilently } = useAuth();
  const {
    setHighlightUntranslatedPreference,
    highlightUntranslatedPreference,
  } = useCookieContext();

  const [language, setLanguage] = React.useState<LanguageData | undefined>();
  const [highlightUntranslated, setHighlightUntranslated] = React.useState(
    highlightUntranslatedPreference ?? false,
  );

  const { control, setValue, watch } = useForm<FilterFormValues>({
    defaultValues: {
      target: FilterTarget.Key,
      type: FilterType.Contains,
      value: undefined,
    },
  });

  const filterData = watch();

  const {
    data: projectDetails,
    isFetching: isProjectDetailsFetching,
    isError: isProjectDetailsError,
    error: projectDetailsError,
    refetch: refetchProjectDetails,
  } = useQuery(
    ['project-details', projectId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await projectService.getProjectDetails(
        accessToken,
        Number(projectId),
        true,
        true,
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    isLoading: isReleaseFileDownloading,
    isError: isReleaseFileDownloadError,
    error: releaseFileDownloadError,
    mutate: downloadReleaseFile,
  } = useMutation(
    async (releaseId: number) => {
      const accessToken = await getAccessTokenSilently();

      return await gitService.downloadReleaseFiles(
        accessToken,
        Number(releaseId),
      );
    },
    {
      onSuccess: (data) => {
        fileDownload(data.file, data.fileName);
      },
    },
  );

  const sortedTranslatedLangOptions: LanguageData[] | undefined =
    React.useMemo(() => {
      return projectDetails?.langs
        .map((lang) => ({ ...lang, name: t(lang.name) }))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    }, [projectDetails, i18n.language]);

  const latestVersionName = React.useMemo(() => {
    return (
      projectDetails?.versions?.sort((a, b) => (b.id > a.id ? 1 : -1))[0]
        ?.name ?? t('release-status.unreleased')
    );
  }, [projectDetails, i18n.language]);

  const latestReleasedVersion = React.useMemo(() => {
    return projectDetails?.versions
      ?.filter((version) => version.status.code === ReleaseStatus.Released)
      .sort((a, b) => (b.id > a.id ? 1 : -1))[0];
  }, [projectDetails]);

  const handleHighlightUntranslatedToggle = (checked: boolean) => {
    setHighlightUntranslated(checked);
    setHighlightUntranslatedPreference(checked);
  };

  const handleGithubNav = () => {
    if (projectDetails?.repoName && projectDetails.repoOwner) {
      window.open(
        `https://github.com/${projectDetails?.repoOwner}/${projectDetails?.repoName}`,
        '_blank',
      );
    }
  };

  const handleDownload = () => {
    if (latestReleasedVersion) {
      downloadReleaseFile(latestReleasedVersion.id);
    }
  };

  return (
    <>
      <ErrorMessagePopup
        isError={isProjectDetailsError}
        error={projectDetailsError}
        retry={refetchProjectDetails}
      />
      <ErrorMessagePopup
        isError={isReleaseFileDownloadError}
        error={releaseFileDownloadError}
        retry={handleDownload}
      />
      <Link
        startDecorator={<FontAwesomeIcon icon={faAngleLeft} />}
        fontSize={'18px'}
        component={RouterLink}
        to={`${Page.ProjectRoute}/${projectId}`}
      >
        {t('page-title.project-details')}
      </Link>
      <Heading style={{ margin: '8px 0px 24px' }}>
        {t('page-title.project-translations')}
      </Heading>
      <div style={{ display: 'flex', gap: '24px' }}>
        <div style={{ width: '75%', display: 'flex', gap: '16px' }}>
          <Typography fontSize='14px'>
            {t('project-translations.intro')}
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              justifyContent: 'flex-end',
              flexGrow: 1,
            }}
          >
            <Button
              startDecorator={<FontAwesomeIcon icon={faGithub} />}
              sx={{ textWrap: 'nowrap' }}
              color='neutral'
              disabled={!projectDetails?.repoName || !projectDetails.repoOwner}
              onClick={handleGithubNav}
            >
              {t('translations.github-link')}
            </Button>
            <Button
              startDecorator={<FontAwesomeIcon icon={faDownload} />}
              sx={{ textWrap: 'nowrap' }}
              disabled={
                !latestReleasedVersion ||
                !projectDetails?.repoName ||
                !projectDetails.repoOwner
              }
              onClick={handleDownload}
              loading={isReleaseFileDownloading}
            >
              {t('translations.download-json')}
            </Button>
          </div>
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}
        >
          <BasicReleaseDetails
            projectName={generalUtil.formatData(projectDetails?.name)}
            versionName={latestVersionName}
            isLoading={isProjectDetailsFetching}
            latestVersion
          />
        </div>
      </div>
      <div
        style={{
          marginTop: '24px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Card>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', gap: '48px', alignItems: 'center' }}>
              <div style={{ width: '250px' }}>
                <Subtitle>{t('common.language')}</Subtitle>
                <Skeleton
                  variant='rectangular'
                  loading={isProjectDetailsFetching}
                >
                  <Select
                    placeholder={`${t('common.language')}...`}
                    startDecorator={
                      language ? (
                        <CountryFlag languageCode={language.code} />
                      ) : undefined
                    }
                    value={language}
                    onChange={(event, newValue) => {
                      setLanguage(newValue as LanguageData);
                    }}
                  >
                    {sortedTranslatedLangOptions?.map((lang) => (
                      <Option value={lang} key={lang.code}>
                        <CountryFlag languageCode={lang.code} />
                        {lang.name}
                      </Option>
                    ))}
                  </Select>
                </Skeleton>
              </div>
              <div>
                <Subtitle>{t('translations-table.filter.label')}</Subtitle>
                <div style={{ display: 'flex', gap: '8px' }}>
                  <FormControl>
                    <Controller
                      name='target'
                      control={control}
                      disabled={!language}
                      render={({ field: { onChange, value, disabled } }) => (
                        <Select
                          sx={{ width: '200px' }}
                          value={value}
                          disabled={disabled}
                          onChange={(_e, value) => onChange(value)}
                        >
                          <Option value={FilterTarget.Key}>
                            {t('keys-table.headings.key')}
                          </Option>
                          <Option value={FilterTarget.Primary}>
                            {t('keys-table.headings.primary-value')}
                          </Option>
                          <Option value={FilterTarget.Translated}>
                            {t('translations-table.filter.translated-value')}
                          </Option>
                          <Option value={FilterTarget.Context}>
                            {t('keys-table.headings.context')}
                          </Option>
                        </Select>
                      )}
                    />
                  </FormControl>
                  <FormControl>
                    <Controller
                      name='type'
                      control={control}
                      disabled={!language}
                      render={({ field: { onChange, value, disabled } }) => (
                        <Select
                          sx={{ width: '150px' }}
                          value={value}
                          disabled={disabled}
                          onChange={(_e, value) => onChange(value)}
                        >
                          <Option value={FilterType.Contains}>
                            {t('translations-table.filter.contains')}
                          </Option>
                          <Option value={FilterType.Starts}>
                            {t('translations-table.filter.starts')}
                          </Option>
                          <Option value={FilterType.Equals}>
                            {t('translations-table.filter.equals')}
                          </Option>
                        </Select>
                      )}
                    />
                  </FormControl>
                  <FormControl>
                    <Controller
                      name='value'
                      control={control}
                      disabled={!language}
                      render={({ field: { onChange, value, disabled } }) => (
                        <Input
                          value={value}
                          onChange={onChange}
                          disabled={disabled}
                          placeholder={`${t('translations-table.filter.placeholder')}...`}
                          sx={{ width: '250px' }}
                          endDecorator={
                            value ? (
                              <IconButton
                                onClick={() => setValue('value', '')}
                              >
                                <FontAwesomeIcon
                                  icon={faXmark}
                                  color={theme.colour.grey}
                                />
                              </IconButton>
                            ) : undefined
                          }
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <Subtitle>{t('translations.highlight-untranslated')}</Subtitle>
                <div
                  style={{
                    flexGrow: 1,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Switch
                    size='lg'
                    checked={highlightUntranslated}
                    disabled={!language}
                    onChange={(event) =>
                      handleHighlightUntranslatedToggle(event.target.checked)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
        {language ? (
          <ProjectTranslationsTable
            language={language}
            projectId={Number(projectId)}
            highlightUntranslated={highlightUntranslated}
            filterData={filterData}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px',
              marginTop: '16px',
              color: theme.colour.grey,
            }}
          >
            <FontAwesomeIcon icon={faCircleInfo} size='2x' />
            <Typography sx={{ color: 'inherit' }}>
              {t('translations.select-language')}
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectTranslations;
