import React from 'react';
import translationService from '../../service/translation';
import useAuth from '../../util/auth-hook';
import { LanguageData } from '../../types/misc';
import { useQuery } from 'react-query';
import LoadingMessage from '../loading-message/loading-message';
import ErrorMessagePopup from '../error-message/error-message-popup/error-message-popup';
import Card from '../card/card';
import {
  Checkbox,
  IconButton,
  Skeleton,
  Table,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';
import keysTableStyles from '../keys-table/keys-table.styles';
import translationsTableStyles from '../translations-table/translations-table.styles';
import { css } from 'aphrodite';
import { useTranslation } from 'react-i18next';
import theme from '../../styles/theme';
import AutoTranslatedIndicator from '../auto-translated-indicator/auto-translated-indicator';
import ProfileName from '../profile-name/profile-name';
import userService from '../../service/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowTurnDown,
  faCircleInfo,
  faFileLines,
} from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import generalUtil from '../../util/general';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../types/navigation';
import { FilterFormValues, FilterType } from '../../pages/project-translations/project-translations';

export type ProjectTranslationsTableProps = {
  projectId: number;
  language: LanguageData;
  highlightUntranslated?: boolean;
  filterData?: FilterFormValues;
};

const ProjectTranslationsTable = ({
  projectId,
  language,
  highlightUntranslated,
  filterData
}: ProjectTranslationsTableProps) => {
  const { getAccessTokenSilently } = useAuth();
  const joyUiTheme = useTheme();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const {
    data: translations,
    isFetching: isTranslationsFetching,
    isError: isTranslationsError,
    error: translationsError,
    refetch: refetchTranslations,
  } = useQuery(
    ['project-translations', projectId, language.code],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await translationService.getProjectTranslations(
        accessToken,
        projectId,
        language.code,
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  );

  const rows = React.useMemo(() => {
    if (filterData?.value) {
      switch(filterData.type) {
        case FilterType.Contains: {
          return translations?.filter((data) => data[filterData.target]?.trim().toLowerCase().includes(filterData.value?.trim().toLowerCase() as string));
        }
        case FilterType.Equals: {
          return translations?.filter((data) => data[filterData.target]?.trim().toLowerCase() === filterData.value?.trim().toLowerCase())
        }
        case FilterType.Starts: {
          return translations?.filter((data) => data[filterData.target]?.trim().toLowerCase().startsWith(filterData.value?.trim().toLowerCase() as string))
        }
      }
    }

    return translations;
  }, [translations, filterData]);

  const {
    data: projectParticipantsUserData,
    isFetching: isProjectParticipantsFetching,
    isError: isProjectParticipantsError,
    error: projectParticipantsError,
    refetch: refetchProjectParticipants,
  } = useQuery(
    ['project-participants', projectId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await userService.getProjectParticipants(accessToken, projectId);
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  );

  const handleReleaseNav = (releaseId: number) => {
    navigate(
      `${Page.ProjectRoute}/${projectId}/${releaseId}/${Page.ReviewTranslations}`,
    );
  };

  return (
    <>
      <LoadingMessage
        isLoading={isTranslationsFetching || isProjectParticipantsFetching}
      />
      <ErrorMessagePopup
        isError={isTranslationsError}
        error={translationsError}
        retry={refetchTranslations}
      />
      <ErrorMessagePopup
        isError={isProjectParticipantsError}
        error={projectParticipantsError}
        retry={refetchProjectParticipants}
      />
      <Card data-testid={`project-translations-table-${language.code}`}>
        <Table>
          <thead>
            <tr data-testid='translations-table-heading'>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '14%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  {t('keys-table.headings.key')}
                  <Tooltip
                    title={t('translations-table.key-tooltip')}
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-key'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '20%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  {t('keys-table.headings.primary-value')}
                  <Tooltip
                    title={t('translations-table.primary-value-tooltip')}
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-primaryvalue'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '22%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  {t(language.name)} {t('translations-table.translated-value')}
                  <Tooltip
                    title={t('translations-table.translated-value-tooltip', {
                      lang: t(language.name),
                    })}
                    placement='top'
                    arrow
                    sx={{ maxWidth: '400px' }}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-translatedvalue'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '8%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  {t('keys-table.headings.placeholder')}
                  <Tooltip
                    title={t('translations-table.placeholder-tooltip')}
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-placeholder'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '15%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  {t('keys-table.headings.context')}
                  <Tooltip
                    title={t('translations-table.context-tooltip')}
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-context'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '12%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  {t('translations-table.last-modified')}
                  <Tooltip
                    title={t('translations-table.last-modified-tooltip')}
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-translator'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '6%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  {t('translations-table.version')}
                  <Tooltip
                    title={t('translations-table.version-tooltip')}
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-version'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '3%' }}
              />
            </tr>
          </thead>
          <tbody>
            {isTranslationsFetching ? (
              <tr>
                <td colSpan={8}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                    }}
                  >
                    {[
                      { key: 'loader-1' },
                      { key: 'loader-2' },
                      { key: 'loader-3' },
                      { key: 'loader-4' },
                    ].map((data, index) => (
                      <Skeleton
                        key={`${data.key}-${index}`}
                        variant='rectangular'
                        height={'50px'}
                      />
                    ))}
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {rows?.length === 0 ? (
                  <tr>
                    <td colSpan={8}>
                      <div
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '16px 0', gap: '4px' }}
                      >
                        <Typography fontSize='18px' fontWeight={600}>
                          {t(filterData?.value ? 'translations-table.filter.no-results' : 'project-translations.no-translations.heading')}
                        </Typography>
                        <Typography>
                        {t(filterData?.value ? 'translations-table.filter.no-results-body' : 'project-translations.no-translations.body')}
                        </Typography>
                      </div>
                    </td>
                  </tr>
                ) : (
                  rows?.map((data) => (
                    <tr
                      key={`${data.id}-${data.key}`}
                      data-testid={`project-translation-table-row-${data.id}`}
                      style={{
                        backgroundColor:
                          highlightUntranslated && !data.translatedValue
                            ? joyUiTheme.colorSchemes.light.palette.warning[200]
                            : undefined,
                      }}
                    >
                      <td>
                        <div style={{ display: 'flex' }}>
                          <Typography
                            fontFamily={'monospace'}
                            variant='soft'
                            sx={{ overflowWrap: 'anywhere' }}
                          >
                            {data.key}
                          </Typography>
                        </div>
                      </td>
                      <td>
                        <div style={{ overflowWrap: 'anywhere' }}>
                          {data.primaryValue}
                        </div>
                      </td>
                      <td>
                        {data.translatedValue ? (
                          <div style={{ overflowWrap: 'anywhere' }}>
                            {data.translatedValue}
                          </div>
                        ) : (
                          <div
                            style={{
                              color: theme.colour.grey,
                              fontStyle: 'italic',
                            }}
                          >
                            {`( ${t('translations-table.blank')} )`}
                          </div>
                        )}
                      </td>
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Checkbox checked={data.placeholder} disabled />
                        </div>
                      </td>
                      <td>
                        <div style={{ overflowWrap: 'anywhere' }}>
                          {data.context}
                        </div>
                      </td>
                      <td>
                        <div>
                          {data.autoTranslated ? (
                            <AutoTranslatedIndicator
                              initiatorName={
                                projectParticipantsUserData?.find(
                                  (user) => user.id === data.updatedBy,
                                )?.name.full
                              }
                            />
                          ) : (
                            <ProfileName
                              user={projectParticipantsUserData?.find(
                                (user) => user.id === data.updatedBy,
                              )}
                              variant='xsmall'
                            />
                          )}
                        </div>
                      </td>
                      <td>
                        <Tooltip
                          title={
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                              }}
                            >
                              <FontAwesomeIcon icon={faClock} />
                              <div>
                                {data.release.releaseDate
                                  ? generalUtil.formatDate(
                                      data.release.releaseDate,
                                      i18n.language,
                                    )
                                  : t('common.unknown')}
                              </div>
                            </div>
                          }
                          placement='top'
                          arrow
                        >
                          <div
                            style={{
                              textAlign: 'center',
                              fontSize: '18px',
                              fontWeight: 500,
                            }}
                          >
                            {data.release.name}
                          </div>
                        </Tooltip>
                      </td>
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Tooltip
                            title={
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '8px',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faArrowTurnDown}
                                  style={{ transform: 'rotate(270deg)' }}
                                  size='sm'
                                />
                                <div>
                                  {t('translations-table.go-to', {
                                    releaseName: data.release.name,
                                  })}
                                </div>
                              </div>
                            }
                            placement='top'
                            arrow
                          >
                            <IconButton
                              color='primary'
                              variant='solid'
                              aria-label={t('common.edit')}
                              size='sm'
                              onClick={() => handleReleaseNav(data.release.id)}
                            >
                              <FontAwesomeIcon icon={faFileLines} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </>
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default ProjectTranslationsTable;
