import { StyleSheet } from 'aphrodite';

const landingPageStyles = StyleSheet.create({
  largeButton: {
    fontSize: '20px',
    padding: '12px 36px',
    ':hover': {
      transform: 'scale(1.1)',
    },
    transition: 'all 0.5s ease',
  },
  cardLearnMore: {
    ':hover': {
      '::after': {
        content: "' →'",
      },
    },
  },
  cardHover: {
    ':hover': {
        transform: 'scale(1.1)',
        transition: 'all 0.5s ease'
    }
  }
});

export default landingPageStyles;
