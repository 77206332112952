import {
  Button,
  DialogContent,
  DialogTitle,
  Link,
  Modal,
  ModalClose,
  ModalDialog,
  Switch,
  Typography,
} from '@mui/joy';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { useCookieContext } from '../../util/cookie-context';
import { Page } from '../../types/navigation';
import { Trans, useTranslation } from 'react-i18next';

export type CookiePreferencesProps = {
  isPublic?: boolean;
}

const CookiePreferences = ({ isPublic }: CookiePreferencesProps) => {
  const [changesSaved, setChangesSaved] = React.useState(false);
  const {
    closeCookiePreferencesModal,
    isCookiePreferencesModalOpen,
    nonEssentialConsent,
    grantNonEssentialCookieConsent,
    revokeNonEssentialCookieConsent,
  } = useCookieContext();

  const { t } = useTranslation();

  const handleClose = () => {
    setChangesSaved(false);
    closeCookiePreferencesModal();
  };

  const handleInitialAccept = () => {
    grantNonEssentialCookieConsent();
    handleClose();
  };

  const handleInitialReject = () => {
    revokeNonEssentialCookieConsent();
    handleClose();
  };

  const handleToggleChange = () => {
    if (nonEssentialConsent) {
      revokeNonEssentialCookieConsent();
    } else {
      grantNonEssentialCookieConsent();
    }
    setChangesSaved(true);
  };

  return (
    <Modal
      open={isCookiePreferencesModalOpen}
      onClose={nonEssentialConsent !== undefined ? handleClose : undefined}
    >
      <ModalDialog maxWidth={'500px'}>
        <DialogTitle>{t('cookie-preferences.title')}</DialogTitle>
        {nonEssentialConsent !== undefined && <ModalClose />}
        <DialogContent>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
          >
            <div>{t('cookie-preferences.essential-info')}</div>
            <div>
              <Trans
                i18nKey='cookie-preferences.functional-info'
                components={{ b: <b /> }}
              />
            </div>
            {nonEssentialConsent === undefined ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '64px',
                  margin: '16px 0',
                }}
              >
                <Button size='lg' onClick={handleInitialAccept}>
                  {t('cookie-preferences.accept')}
                </Button>
                <Button size='lg' onClick={handleInitialReject}>
                  {t('cookie-preferences.reject')}
                </Button>
              </div>
            ) : (
              <div>
                <div style={{ fontWeight: 600 }}>
                  {t('cookie-preferences.change-preference-prompt', {
                    consent: nonEssentialConsent
                      ? t('cookie-preferences.consented')
                      : t('cookie-preferences.rejected'),
                  })}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '16px',
                    margin: '24px 0',
                    alignItems: 'center',
                    fontSize: '20px',
                    fontWeight: 600,
                  }}
                >
                  <Typography fontSize='inherit'>
                    {t('cookie-preferences.reject')}
                  </Typography>
                  <Switch
                    size='lg'
                    sx={{
                      '--Switch-trackHeight': '32px',
                      '--Switch-trackWidth': '64px',
                      '--Switch-thumbSize': '24px',
                      '--Switch-trackRadius': '32px',
                    }}
                    checked={nonEssentialConsent}
                    onChange={handleToggleChange}
                  />
                  <Typography fontSize='inherit'>
                    {t('cookie-preferences.accept')}
                  </Typography>
                </div>
                {changesSaved && (
                  <Typography color='success'>
                    {t('cookie-preferences.saved')}
                  </Typography>
                )}
              </div>
            )}
            <div>{t('cookie-preferences.manage-info')}</div>
            <div>
              {t('cookie-preferences.more-info')}{' '}
              <Link
                component={RouterLink}
                to={isPublic ? Page.PublicPrivacy : Page.Privacy}
                onClick={() => handleClose()}
              >
                {t('privacy.title')}
              </Link>
              .
            </div>
          </div>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default CookiePreferences;
