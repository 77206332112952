import React from 'react';
import Heading from '../../components/heading/heading';
import Card from '../../components/card/card';
import {
  FormControl,
  Input,
  FormHelperText,
  Autocomplete,
  AutocompleteOption,
  ListItemDecorator,
  ListItemContent,
  useTheme,
  Skeleton,
  Button,
  Select,
  Option,
  Tooltip,
  Typography,
} from '@mui/joy';
import { useMutation, useQuery } from 'react-query';
import { User as Auth0User } from '@auth0/auth0-react';
import miscService from '../../service/misc';
import LoadingMessage from '../../components/loading-message/loading-message';
import { Controller, useForm } from 'react-hook-form';
import { LanguageData } from '../../types/misc';
import CountryFlag from '../../components/country-flag/country-flag';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faTrashCan,
  faCrown,
  faCheck,
  faLock,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import userService from '../../service/user';
import { ProjectRole, User } from '../../types/user';
import authUtil from '../../util/auth';
import ProfileName from '../../components/profile-name/profile-name';
import { css } from 'aphrodite';
import commonStyles from '../../styles/common.styles';
import ErrorMessagePopup from '../../components/error-message/error-message-popup/error-message-popup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Page } from '../../types/navigation';
import {
  ProjectCreateInput,
  UpdateProjectDetailsInput,
} from '../../types/project';
import projectService from '../../service/project';
import useAuth from '../../util/auth-hook';
import FormLabelWithIcon from '../../components/form-label-with-icon/form-label-with-icon';
import { GitRepoValidationInput } from '../../types/git';
import gitService from '../../service/git';
import GitValidationErrorMessage from '../../components/git-validation-error-message/git-validation-error-message';
import { useTranslation } from 'react-i18next';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import GitSetupGuide from '../../components/git-setup-guide/git-setup-guide';

type CreateProjectFormValues = {
  projectName: string;
  defaultLanguage: LanguageData;
  languages: LanguageData[];
  repoOwner: string;
  repoName: string;
  pat?: string;
  emailAddress?: string;
};

type ProjectUserDetails = {
  user: User;
  role?: ProjectRole;
};

export type CreateProjectProps = {
  edit?: boolean;
};

const CreateProject = ({ edit }: CreateProjectProps) => {
  const { getAccessTokenSilently, user } = useAuth();

  const joyUiTheme = useTheme();

  const navigate = useNavigate();
  const { projectId } = useParams();

  const { t, i18n } = useTranslation();

  const [projectUsers, setProjectUsers] = React.useState<ProjectUserDetails[]>(
    edit
      ? []
      : [
          {
            user: authUtil.getCurrentUserObject(user as Auth0User),
            role: ProjectRole.Admin,
          },
        ],
  );

  // the searchForUserByEmail endpoint will return a 404 if the user cannot be found
  // We don't want a general error message shown in this case
  // therefore this state will track whether the error returned is a 404, and so a different error should be shown
  const [isNewUserNotFoundError, setIsNewUserNotFoundError] =
    React.useState(false);

  // state to keep track of whether a user specified has already been added
  const [newUserAlreadyAdded, setNewUserAlreadyAdded] = React.useState(false);

  const [isGitSetupGuideOpen, setIsGitSetupGuideOpen] = React.useState(false);

  const addUserToProjectList = (user: User) => {
    setProjectUsers((existingUsers) => [
      ...existingUsers,
      { user, role: undefined },
    ]);
  };

  const removeUserFromProjectList = (userToRemove: User) => {
    setProjectUsers(
      projectUsers.filter((addedUser) => addedUser.user.id !== userToRemove.id),
    );
  };

  // iterates over the projectUsers state array
  // - if the user's id matches that to update, update the object with the new role
  // - else, leave the object as it is
  const changeUserRole = (userId: string, role: ProjectRole) => {
    setProjectUsers((existingUsers) =>
      existingUsers.map((existingUser) =>
        existingUser.user.id === userId
          ? { ...existingUser, role }
          : existingUser,
      ),
    );
  };

  const {
    data: languages,
    isFetching: isLanguagesFetching,
    isError: isLanguagesError,
    error: languagesError,
    refetch: refetchLanguages,
  } = useQuery(
    'languages',
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await miscService.getLanguages(accessToken);
    },
    {
      staleTime: 1000 * 60 * 5,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: projectRoles,
    isFetching: isProjectRolesFetching,
    isError: isProjectRolesError,
    error: projectRolesError,
    refetch: refetchProjectRoles,
  } = useQuery(
    'roles-assignable',
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await miscService.getRoles(accessToken, true);
    },
    {
      staleTime: 1000 * 60 * 5,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  // useMutation is used here rather than useQuery as caching not required, and allows for onSuccess/onError callbacks and a specific mutate function
  const {
    isLoading: isNewUserLoading,
    isError: isNewUserError,
    error: newUserError,
    mutate: searchForUser,
    reset: resetNewUser,
  } = useMutation(
    async (email: string) => {
      const accessToken = await getAccessTokenSilently();
      return await userService.searchForUserByEmail(accessToken, email);
    },
    {
      onError: (error) => {
        if (axios.isAxiosError(error) && error.status === 404) {
          resetNewUser();
          setIsNewUserNotFoundError(true);
        }
      },
      onSuccess: (user) => {
        for (const existingUser of projectUsers) {
          if (user.id === existingUser.user.id) {
            resetNewUser();
            setNewUserAlreadyAdded(true);
            return;
          }
        }
        addUserToProjectList(user);
        setValue('emailAddress', undefined);
      },
    },
  );

  const {
    isLoading: isProjectCreationLoading,
    isError: isProjectCreationError,
    error: projectCreationError,
    mutate: submitProject,
  } = useMutation(
    async () => {
      const accessToken = await getAccessTokenSilently();

      const {
        projectName,
        defaultLanguage,
        languages,
        repoName,
        repoOwner,
        pat,
      } = getValues();

      const projectInput: ProjectCreateInput = {
        name: projectName,
        defaultLang: defaultLanguage.code,
        langs: languages.map((langData) => langData.code),
        repoName,
        repoOwner,
        pat: pat as string,
        users: projectUsers.map((projectUser) => ({
          userId: projectUser.user.id,
          role: projectUser.role as ProjectRole,
        })),
      };

      return await projectService.createProject(accessToken, projectInput);
    },
    {
      onSuccess: (data) => {
        navigate(`${Page.ProjectRoute}/${data.id}`);
      },
    },
  );

  const {
    isLoading: isProjectEditLoading,
    isError: isProjectEditError,
    error: projectEditError,
    mutate: submitEditProject,
  } = useMutation(
    async () => {
      const accessToken = await getAccessTokenSilently();

      const { projectName, pat } = getValues();

      const projectInput: UpdateProjectDetailsInput = {
        name: projectName,
        pat: pat && pat.length > 0 ? pat : undefined,
        users: projectUsers.map((projectUser) => ({
          userId: projectUser.user.id,
          role: projectUser.role as ProjectRole,
        })),
      };

      return await projectService.updateProjectDetails(
        accessToken,
        Number(projectId),
        projectInput,
      );
    },
    {
      onSuccess: (data) => {
        navigate(`${Page.ProjectRoute}/${data.id}`);
      },
    },
  );

  const {
    isLoading: isValidateGitRepoLoading,
    isSuccess: isValidateGitRepoSuccess,
    isError: isValidateGitRepoError,
    error: validateGitRepoError,
    mutate: validateGitRepo,
    reset: resetValidateGitRepo,
  } = useMutation(async () => {
    const accessToken = await getAccessTokenSilently();

    const { repoName, repoOwner, pat } = getValues();

    const input: GitRepoValidationInput = {
      repoName,
      repoOwner,
      pat: pat as string,
    };

    return await gitService.validateRepo(accessToken, input);
  });

  const {
    isFetching: isExistingProjectDetailsLoading,
    isSuccess: isExistingProjectDetailsSuccess,
    isError: isExistingProjectDetailsError,
    error: existingProjectDetailsError,
    refetch: refetchExistingProjectDetails,
  } = useQuery(
    ['project-details-no-versions', projectId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await projectService.getProjectDetails(
        accessToken,
        Number(projectId),
        false,
        true,
      );
    },
    {
      staleTime: 1000 * 60 * 5,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      enabled: edit === true,
      onSuccess: (prjDetails) => {
        setValue('projectName', prjDetails.name);
        setValue('repoOwner', prjDetails.repoOwner ?? '');
        setValue('repoName', prjDetails.repoName ?? '');
        setValue('defaultLanguage', prjDetails.defaultLang);
        setValue('languages', prjDetails.langs);
        setProjectUsers(prjDetails.users ?? []);
      },
    },
  );

  const validationSchema = Yup.object().shape({
    projectName: Yup.string()
      .required(t('create-project.errors.project-name.required'))
      .max(
        255,
        t('create-project.errors.project-name.length', { maxLength: 255 }),
      ),
    defaultLanguage: Yup.object()
      .shape({
        code: Yup.string().required(
          t('create-project.errors.default-lang.required'),
        ),
        name: Yup.string().required(
          t('create-project.errors.default-lang.required'),
        ),
      })
      .required(t('create-project.errors.default-lang.required')),
    languages: Yup.array()
      .min(1, t('create-project.errors.languages.min', { min: 1 }))
      .required(t('create-project.errors.languages.required')),
    repoOwner: Yup.string()
      .required(t('create-project.errors.repo-owner.required'))
      .max(40, t('create-project.errors.repo-owner.max', { maxLength: 40 })),
    repoName: Yup.string()
      .required(t('create-project.errors.repo-name.required'))
      .max(100, t('create-project.errors.repo-name.max', { maxLength: 100 })),
    pat: Yup.string().when([], {
      is: () => !edit,
      then: (schema) =>
        schema
          .required(t('create-project.errors.pat.required'))
          .max(255, t('create-project.errors.pat.max', { maxLength: 255 })),
      otherwise: (schema) => schema.notRequired(),
    }),
    emailAddress: Yup.string().email(t('create-project.errors.email.valid')),
  });

  const {
    register,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<CreateProjectFormValues>({
    defaultValues: {
      projectName: undefined,
      defaultLanguage: {
        code: i18n.language,
        name: t(`languages.${i18n.language.toLowerCase()}`),
      },
      languages: [],
      repoOwner: undefined,
      repoName: undefined,
      pat: undefined,
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const projectNameValue = watch('projectName');
  const selectedLanguages = watch('languages');
  const pat = watch('pat');

  const { onChange: onEmailAddressChange, ...emailAddressRegisterProps } =
    register('emailAddress');
  const newUserInput = watch('emailAddress');
  const defaultLang = watch('defaultLanguage');

  const { onChange: onRepoOwnerChange, ...repoOwnerRegisterProps } =
    register('repoOwner');

  const { onChange: onRepoNameChange, ...repoNameRegisterProps } =
    register('repoName');

  const { onChange: onRepoPATChange, ...repoPATRegisterProps } =
    register('pat');

  const canSubmit = React.useMemo(() => {
    const allUsersHaveRoles = projectUsers.every((user) => !!user.role);

    const patValid = edit
      ? !!pat
        ? isValidateGitRepoSuccess
        : true
      : isValidateGitRepoSuccess;

    return isValid && allUsersHaveRoles && patValid;
  }, [projectUsers, isValid, isValidateGitRepoSuccess, pat, edit]);

  const handleGitFieldBlur = () => {
    const { repoName, repoOwner, pat } = getValues();

    if (
      repoName &&
      repoOwner &&
      pat &&
      !errors.repoName &&
      !errors.repoOwner &&
      !errors.pat
    ) {
      validateGitRepo();
    }
  };

  return (
    <>
      <LoadingMessage
        isLoading={isLanguagesFetching || isProjectRolesFetching}
      />
      <ErrorMessagePopup
        isError={isLanguagesError}
        error={languagesError}
        retry={refetchLanguages}
      />
      <ErrorMessagePopup
        isError={isProjectRolesError}
        error={projectRolesError}
        retry={refetchProjectRoles}
      />
      <ErrorMessagePopup
        isError={isProjectCreationError}
        error={projectCreationError}
        retry={submitProject}
      />
      <ErrorMessagePopup
        isError={isExistingProjectDetailsError}
        error={existingProjectDetailsError}
        retry={refetchExistingProjectDetails}
      />
      <ErrorMessagePopup
        isError={isProjectEditError}
        error={projectEditError}
        retry={submitEditProject}
      />
      <ErrorMessagePopup isError={isNewUserError} error={newUserError} />
      <GitSetupGuide
        isOpen={isGitSetupGuideOpen}
        onClose={() => setIsGitSetupGuideOpen(false)}
      />
      <Heading>
        {t(edit ? 'page-title.edit-project' : 'page-title.create-project')}
      </Heading>
      <Card size='lg'>
        <div style={{ display: 'flex', gap: '32px' }}>
          <div style={{ minWidth: '60%' }}>
            <h2 style={{ margin: '0 0 16px 0' }}>
              {t('create-project.project-details')}
            </h2>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
              }}
            >
              <FormControl>
                <FormLabelWithIcon
                  completed={!errors.projectName && !!projectNameValue}
                  errored={!!errors.projectName}
                  required
                >
                  {t('create-project.project-name')}
                </FormLabelWithIcon>
                <Skeleton
                  variant='rectangular'
                  loading={isExistingProjectDetailsLoading}
                >
                  <Input
                    placeholder={`${t('create-project.project-name')}...`}
                    {...register('projectName')}
                    error={!!errors.projectName}
                    disabled={
                      isProjectCreationLoading ||
                      isExistingProjectDetailsLoading ||
                      isProjectEditLoading
                    }
                  />
                </Skeleton>
                {!!errors.projectName && (
                  <FormHelperText
                    sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                  >
                    {errors.projectName.message}
                  </FormHelperText>
                )}
              </FormControl>

              <div>
                <div style={{ display: 'flex', gap: '24px' }}>
                  <div style={{ minWidth: '35%' }}>
                    <FormLabelWithIcon
                      completed={!errors.defaultLanguage && !!defaultLang}
                      errored={!!errors.defaultLanguage}
                      required={!edit}
                    >
                      {t('create-project.default-language')}
                    </FormLabelWithIcon>
                    <Skeleton
                      variant='rectangular'
                      loading={
                        isLanguagesFetching || isExistingProjectDetailsLoading
                      }
                    >
                      <Tooltip
                        open={!edit ? false : undefined}
                        title={t('create-project.cannot-edit')}
                        color='danger'
                        sx={{ cursor: 'not-allowed' }}
                      >
                        <FormControl>
                          <Controller
                            name='defaultLanguage'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                placeholder={`${t('create-project.default-language')}...`}
                                options={languages ?? []}
                                getOptionLabel={(option) => t(option.name)}
                                isOptionEqualToValue={(option, value) =>
                                  option.code === value.code
                                }
                                error={!!errors.defaultLanguage}
                                disabled={
                                  isLanguagesError ||
                                  isProjectCreationLoading ||
                                  isProjectEditLoading ||
                                  edit
                                }
                                renderOption={(props, option) => (
                                  <AutocompleteOption {...props}>
                                    <ListItemDecorator>
                                      <CountryFlag languageCode={option.code} />
                                    </ListItemDecorator>
                                    <ListItemContent>
                                      {t(option.name)}
                                    </ListItemContent>
                                  </AutocompleteOption>
                                )}
                                startDecorator={
                                  value ? (
                                    <CountryFlag languageCode={value.code} />
                                  ) : undefined
                                }
                                value={value}
                                onChange={(event, newValue) => {
                                  onChange(newValue?.code);
                                  // remove selected default language from selected langs
                                  setValue(
                                    'languages',
                                    selectedLanguages.filter(
                                      (lang) => lang.code !== newValue?.code,
                                    ),
                                  );
                                }}
                              />
                            )}
                          />
                          {!!errors.defaultLanguage && (
                            <FormHelperText
                              sx={{
                                color: joyUiTheme.vars.palette.danger[500],
                              }}
                            >
                              {errors.defaultLanguage.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Tooltip>
                    </Skeleton>
                  </div>
                  <div style={{ minWidth: '65%' }}>
                    <FormLabelWithIcon
                      completed={
                        !errors.languages && selectedLanguages.length > 0
                      }
                      errored={!!errors.languages}
                      required={!edit}
                    >
                      {t('create-project.supported-langs')}
                    </FormLabelWithIcon>
                    <div style={{ display: 'flex', gap: '8px', flexGrow: 1 }}>
                      <div style={{ minWidth: '60%', maxWidth: '60%' }}>
                        <Tooltip
                          open={!edit ? false : undefined}
                          title={t('create-project.cannot-edit')}
                          color='danger'
                          sx={{ cursor: 'not-allowed' }}
                        >
                          <FormControl>
                            <Controller
                              name='languages'
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Skeleton
                                  variant='rectangular'
                                  loading={
                                    isLanguagesFetching ||
                                    isExistingProjectDetailsLoading
                                  }
                                >
                                  <Autocomplete
                                    multiple
                                    placeholder={`${t('create-project.supported-langs')}...`}
                                    limitTags={3}
                                    options={
                                      languages?.filter(
                                        (lang) =>
                                          lang.code !== defaultLang.code,
                                      ) ?? []
                                    }
                                    getOptionLabel={(option) => t(option.name)}
                                    isOptionEqualToValue={(option, value) =>
                                      option.code === value.code
                                    }
                                    error={!!errors.languages}
                                    disabled={
                                      isLanguagesError ||
                                      isProjectCreationLoading ||
                                      isProjectEditLoading ||
                                      edit
                                    }
                                    renderOption={(props, option) => (
                                      <AutocompleteOption {...props}>
                                        <ListItemDecorator>
                                          <CountryFlag
                                            languageCode={option.code}
                                          />
                                        </ListItemDecorator>
                                        <ListItemContent>
                                          {t(option.name)}
                                        </ListItemContent>
                                      </AutocompleteOption>
                                    )}
                                    value={value}
                                    onChange={(event, newValue) => {
                                      onChange(newValue);
                                    }}
                                  />
                                </Skeleton>
                              )}
                            />
                            {!!errors.languages && (
                              <FormHelperText
                                sx={{
                                  color: joyUiTheme.vars.palette.danger[500],
                                }}
                              >
                                {errors.languages.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Tooltip>
                      </div>
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns:
                            'repeat(auto-fill, minmax(24px, 1fr))',
                          gridAutoRows: '24px',
                          justifyContent: 'start',
                          gap: '6px',
                          minWidth: '30%',
                        }}
                      >
                        {selectedLanguages.map((language) => (
                          <div
                            key={language.code}
                            style={{
                              width: '24px',
                              height: '24px',
                              margin: '6px 0',
                            }}
                          >
                            <CountryFlag
                              languageCode={language.code}
                              size='24px'
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <FormLabelWithIcon
                  style={{ marginBottom: 0 }}
                  completed={
                    edit
                      ? isExistingProjectDetailsSuccess
                      : isValidateGitRepoSuccess
                  }
                  errored={
                    !!errors.repoName ||
                    !!errors.repoOwner ||
                    isValidateGitRepoError
                  }
                  loading={isValidateGitRepoLoading}
                  required={!edit}
                >
                  {t('create-project.git.heading')}
                </FormLabelWithIcon>
                <FormHelperText sx={{ marginBottom: '8px' }}>
                  <div>
                    <div>{t('create-project.git.instruction')}</div>
                    <div>
                      <b>{t('create-project.git.empty-warning')}</b>
                    </div>
                    <div>
                      <i>{t('create-project.git.example')}</i>
                    </div>
                  </div>
                </FormHelperText>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      <div>https://github.com/</div>
                      <Skeleton
                        variant='rectangular'
                        loading={isExistingProjectDetailsLoading}
                      >
                        <Tooltip
                          open={!edit ? false : undefined}
                          title={t('create-project.cannot-edit')}
                          color='danger'
                          sx={{ cursor: 'not-allowed' }}
                        >
                          <FormControl>
                            <Input
                              placeholder={`${t('create-project.git.namespace')}...`}
                              {...repoOwnerRegisterProps}
                              error={!!errors.repoOwner}
                              disabled={
                                isProjectCreationLoading ||
                                isProjectEditLoading ||
                                edit
                              }
                              onBlur={handleGitFieldBlur}
                              onChange={(e) => {
                                onRepoOwnerChange(e);
                                resetValidateGitRepo();
                              }}
                            />
                          </FormControl>
                        </Tooltip>
                      </Skeleton>
                      <div>/</div>
                      <Skeleton
                        variant='rectangular'
                        loading={isExistingProjectDetailsLoading}
                      >
                        <Tooltip
                          open={!edit ? false : undefined}
                          title={t('create-project.cannot-edit')}
                          color='danger'
                          sx={{ cursor: 'not-allowed' }}
                        >
                          <FormControl>
                            <Input
                              placeholder={`${t('create-project.git.repo-name')}...`}
                              {...repoNameRegisterProps}
                              error={!!errors.repoName}
                              disabled={
                                isProjectCreationLoading ||
                                isProjectEditLoading ||
                                edit
                              }
                              onBlur={handleGitFieldBlur}
                              onChange={(e) => {
                                onRepoNameChange(e);
                                resetValidateGitRepo();
                              }}
                            />
                          </FormControl>
                        </Tooltip>
                      </Skeleton>
                    </div>
                    {!!errors.repoOwner && (
                      <FormHelperText
                        sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                      >
                        {errors.repoOwner.message}
                      </FormHelperText>
                    )}
                    {!!errors.repoName && (
                      <FormHelperText
                        sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                      >
                        {errors.repoName.message}
                      </FormHelperText>
                    )}
                  </div>
                  <div>
                    <Button
                      startDecorator={<FontAwesomeIcon icon={faGithub} />}
                      color='neutral'
                      variant='outlined'
                      onClick={() => setIsGitSetupGuideOpen(true)}
                    >
                      {t('create-project.git.setup.title')}
                    </Button>
                  </div>
                </div>
              </div>
              <FormControl>
                <FormLabelWithIcon
                  completed={isValidateGitRepoSuccess}
                  errored={!!errors.pat || isValidateGitRepoError}
                  loading={isValidateGitRepoLoading}
                  required={!edit}
                >
                  {t('create-project.git.pat')}
                </FormLabelWithIcon>
                <FormHelperText sx={{ marginBottom: '8px' }}>
                  {t('create-project.git.pat-instruction')}
                </FormHelperText>
                <div style={{ display: 'flex', gap: '16px' }}>
                  <Skeleton
                    variant='rectangular'
                    loading={isExistingProjectDetailsLoading}
                  >
                    <Input
                      placeholder={`${t('create-project.git.pat')}...`}
                      {...repoPATRegisterProps}
                      error={!!errors.pat}
                      disabled={
                        isProjectCreationLoading || isProjectEditLoading
                      }
                      onBlur={handleGitFieldBlur}
                      onChange={(e) => {
                        onRepoPATChange(e);
                        resetValidateGitRepo();
                      }}
                      sx={{ display: 'flex', flexGrow: 1 }}
                    />
                  </Skeleton>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      title={`${t('create-project.git.aes')}`}
                      placement='top'
                      arrow
                    >
                      <div style={{ display: 'flex', gap: '8px' }}>
                        <FontAwesomeIcon
                          icon={faLock}
                          size='lg'
                          color={
                            joyUiTheme.colorSchemes.light.palette.warning[500]
                          }
                        />
                        <Typography fontWeight={'bold'} color='warning'>
                          AES-256
                        </Typography>
                      </div>
                    </Tooltip>
                  </div>
                </div>
                {!!errors.pat && (
                  <FormHelperText
                    sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                  >
                    {errors.pat.message}
                  </FormHelperText>
                )}
              </FormControl>
              {isValidateGitRepoError && (
                <div>
                  <GitValidationErrorMessage
                    error={validateGitRepoError}
                    retry={validateGitRepo}
                  />
                </div>
              )}
            </div>
          </div>
          <div style={{ width: '1px', display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: '100%',
                height: '90%',
                backgroundColor: '#B3B3B3',
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              gap: '16px',
              maxWidth: 'calc(40% - 65px)',
            }}
          >
            <h2 style={{ margin: 0 }}>{t('create-project.users.title')}</h2>
            <div>
              <div style={{ display: 'flex', gap: '32px' }}>
                <FormControl sx={{ width: '100%' }}>
                  <Input
                    placeholder={`${t('create-project.users.email-input')}...`}
                    {...emailAddressRegisterProps}
                    onChange={(e) => {
                      onEmailAddressChange(e);
                      setIsNewUserNotFoundError(false);
                      setNewUserAlreadyAdded(false);
                    }}
                    error={!!errors.emailAddress}
                    disabled={
                      isNewUserLoading ||
                      isProjectCreationLoading ||
                      isProjectEditLoading
                    }
                  />
                </FormControl>
                <Button
                  startDecorator={<FontAwesomeIcon icon={faPlus} />}
                  disabled={!!errors.emailAddress || !newUserInput}
                  loading={isNewUserLoading}
                  onClick={() => searchForUser(newUserInput as string)}
                >
                  {t('common.add')}
                </Button>
              </div>
              {isNewUserNotFoundError && (
                <FormHelperText
                  sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                >
                  {t('create-project.users.not-found')}
                </FormHelperText>
              )}
              {newUserAlreadyAdded && (
                <FormHelperText
                  sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                >
                  {t('create-project.users.already-added')}
                </FormHelperText>
              )}
              {!!errors.emailAddress && (
                <FormHelperText
                  sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                >
                  {errors.emailAddress.message}
                </FormHelperText>
              )}
            </div>
            <div
              style={{
                marginTop: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              {isExistingProjectDetailsLoading ? (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                  {[
                    { key: 'loader-1' },
                    { key: 'loader-2' },
                    { key: 'loader-3' },
                    { key: 'loader-4' },
                    { key: 'loader-5' },
                  ].map((data, index) => (
                    <Skeleton
                      key={`${data.key}-${index}`}
                      variant='rectangular'
                      height={'50px'}
                    />
                  ))}
                </div>
              ) : (
                projectUsers.map((projectUser) => (
                  <div
                    key={projectUser.user.id}
                    style={{ display: 'flex', gap: '16px' }}
                  >
                    <div style={{ width: '50%', wordBreak: 'break-all' }}>
                      <ProfileName
                        user={projectUser.user}
                        variant='large'
                        tagline={
                          <Typography fontSize='inherit' noWrap>
                            {projectUser.user.email}
                          </Typography>
                        }
                      />
                    </div>
                    {projectUser.role === ProjectRole.Admin ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        {t('roles.admin')}
                        <FontAwesomeIcon
                          icon={faCrown}
                          color={
                            joyUiTheme.colorSchemes.light.palette.warning[400]
                          }
                        />
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            width: '40%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Skeleton
                            variant='rectangular'
                            loading={isProjectRolesFetching}
                          >
                            <Select
                              sx={{ width: '100%' }}
                              disabled={
                                isProjectRolesFetching ||
                                isProjectRolesError ||
                                isProjectEditLoading
                              }
                              placeholder={t(
                                'create-project.users.select-role',
                              )}
                              value={projectUser.role}
                              onChange={(_event, newValue) => {
                                changeUserRole(
                                  projectUser.user.id,
                                  newValue as ProjectRole,
                                );
                              }}
                            >
                              {projectRoles?.map((role) => (
                                <Option value={role.id} key={role.id}>
                                  {t(role.description)}
                                </Option>
                              ))}
                            </Select>
                          </Skeleton>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexGrow: 1,
                            alignItems: 'center',
                          }}
                        >
                          <button
                            className={css(commonStyles.nativeReset)}
                            onClick={() =>
                              removeUserFromProjectList(projectUser.user)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              color={
                                joyUiTheme.colorSchemes.dark.palette.danger[500]
                              }
                            />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </Card>
      <div
        style={{
          marginTop: '32px',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '16px',
        }}
      >
        <Button
          color='danger'
          size='lg'
          startDecorator={
            <FontAwesomeIcon icon={edit ? faXmark : faTrashCan} />
          }
          onClick={() =>
            navigate(
              edit ? `${Page.ProjectRoute}/${projectId}` : Page.Dashboard,
            )
          }
          disabled={isProjectCreationLoading}
        >
          {t('common.cancel')}
        </Button>
        {edit ? (
          <Button
            size='lg'
            startDecorator={<FontAwesomeIcon icon={faCheck} />}
            disabled={
              !canSubmit || isProjectCreationLoading || isProjectEditLoading
            }
            loading={isProjectEditLoading}
            onClick={() => submitEditProject()}
          >
            {t('common.confirm')}
          </Button>
        ) : (
          <Button
            size='lg'
            startDecorator={<FontAwesomeIcon icon={faCheck} />}
            disabled={!canSubmit || isProjectCreationLoading}
            loading={isProjectCreationLoading}
            onClick={() => submitProject()}
          >
            {t('common.create')}
          </Button>
        )}
      </div>
    </>
  );
};

export default CreateProject;
