import {
  faClock,
  faCheck,
  faXmark,
  faExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ReleasePunctuality } from '../project-version/project-version';
import { Badge, useTheme } from '@mui/joy';

export type PunctualityIndicatorProps = {
  punctuality: ReleasePunctuality;
  'data-testid'?: string;
};

const PunctualityIndicator = ({
  punctuality,
  'data-testid': dataTestId,
}: PunctualityIndicatorProps) => {
  const joyUiTheme = useTheme();

  const iconColour = React.useMemo(() => {
    return joyUiTheme.colorSchemes.light.palette[
      punctuality === 'ontime'
        ? 'success'
        : punctuality === 'late'
          ? 'danger'
          : 'warning'
    ][punctuality === 'today' ? 400 : 500];
  }, [punctuality, joyUiTheme]);

  return (
    <Badge
      badgeContent={
        <FontAwesomeIcon
          icon={
            punctuality === 'ontime'
              ? faCheck
              : punctuality === 'late'
                ? faXmark
                : faExclamation
          }
          size='xs'
        />
      }
      color={
        punctuality === 'ontime'
          ? 'success'
          : punctuality === 'late'
            ? 'danger'
            : 'warning'
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      size='sm'
      badgeInset={'5%'}
    >
      <FontAwesomeIcon
        icon={faClock}
        data-testid={dataTestId}
        color={iconColour}
        size='2x'
      />
    </Badge>
  );
};

export default PunctualityIndicator;
