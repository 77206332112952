import React from 'react';

interface ScreenSizeContextType {
  screenWidth: number;
  screenScrollValue: number;
  isScreenScrolled: boolean;
}

const ScreenSizeContext = React.createContext<
  ScreenSizeContextType | undefined
>(undefined);

export const useScreenSizeContext = () => {
  const context = React.useContext(ScreenSizeContext);
  if (!context) {
    throw new Error('useScreenSize must be used within a ScreenSizeProvider');
  }
  return context;
};

const determineIfScrolled = () => {
  return window.scrollY >= 50;
};

export const ScreenSizeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [isScrolled, setIsScrolled] = React.useState(determineIfScrolled());
  

  React.useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    const handleScroll = () => setIsScrolled(determineIfScrolled());

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ScreenSizeContext.Provider
      value={{
        screenWidth,
        isScreenScrolled: isScrolled,
        screenScrollValue: window.scrollY,
      }}
    >
      {children}
    </ScreenSizeContext.Provider>
  );
};
