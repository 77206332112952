import { User } from "./user";

export type LanguageData = {
    code: Language;
    name: string;
}

export const supportedLanguages = [
    "af",
    "sq",
    "am",
    "ar",
    "hy",
    "az",
    "bn",
    "bs",
    "bg",
    "ca",
    "zh",
    "zh-TW",
    "hr",
    "cs",
    "da",
    "fa-AF",
    "nl",
    "en",
    "et",
    "fa",
    "tl",
    "fi",
    "fr",
    "fr-CA",
    "ka",
    "de",
    "el",
    "gu",
    "ht",
    "ha",
    "he",
    "hi",
    "hu",
    "is",
    "id",
    "ga",
    "it",
    "ja",
    "kn",
    "kk",
    "ko",
    "lv",
    "lt",
    "mk",
    "ms",
    "ml",
    "mt",
    "mr",
    "mn",
    "no",
    "ps",
    "pl",
    "pt",
    "pt-PT",
    "pa",
    "ro",
    "ru",
    "sr",
    "si",
    "sk",
    "sl",
    "so",
    "es",
    "es-MX",
    "sw",
    "sv",
    "ta",
    "te",
    "th",
    "tr",
    "uk",
    "ur",
    "uz",
    "vi",
    "cy"
];

const supportedLanguagesConst = [...supportedLanguages] as const;

export type Language = typeof supportedLanguagesConst[number];

export type TranslationResponse = {
    translatedText: string;
}

export type AutoTranslationInput = {
    text: string;
    sourceLang: Language;
    targetLang: Language;
}

export type FileSizeUnit = 'bytes' | 'KB' | 'MB';

export type FileSizeConversionResult = {
    value: number;
    unit: FileSizeUnit;
}

export enum LogActionCode {
    CreateProject = 'PRCPR',
    UpdateProject = 'PRUPR',
    AddUserToProject = 'PRUAD',
    UpdateUserRole = 'PRUUP',
    RemoveUserFromProject = 'PRURM',
    CreateProjectRelease = 'PRCVR',
    ApproveRelease = 'PRAPP',
    AddKeys = 'TRADD',
    UpdateKeys = 'TRUPD',
    DeleteKeys = 'TRDEL',
    UpdateTranslationValues = 'TRTRN',
    BulkAutoTranslate = 'TRAUT',
    ReviewSingleKey = 'TRRSK',
    UnreviewSingleKey = 'TRUSK',
    ReviewAllKeys = 'TRRAK',
    UnreviewAllKeys = 'TRUAK',
    ReviewSingleTranslation = 'TRRST',
    UnreviewSingleTranslation = 'TRUST',
    ReviewAllTranslations = 'TRRAT',
    UnreviewAllTranslations = 'TRUAT',
    ReleaseToGit = 'GTREL'
}

export type ActionLog = {
    id: number;
    projectId: number;
    releaseId?: number;
    date: string;
    user: User;
    actionCode: LogActionCode;
    quantity: number;
    description: string;
    additionalInfo?: Record<string, any>;
}