import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Page from './components/page/page';
import Layout from './components/layout/layout';
import Dashboard from './pages/dashboard/dashboard';
import CreateProject from './pages/create-project/create-project';
import NotFound from './pages/404/not-found';
import ProjectDetails from './pages/project-details/project-details';
import KeysScreen from './pages/keys/keys';
import TranslateScreen from './pages/translate/translate';
import ReleaseTranslations from './pages/release-translations/release-translations';
import Privacy from './pages/privacy/privacy';
import LandingPage from './pages/landing/landing';
import PrivacyPublic from './pages/privacy/privacy-public';
import PublicLayout from './components/layout/public-layout/public-layout';
import ProjectTranslations from './pages/project-translations/project-translations';

const router = createBrowserRouter([
  {
    path: '/',
    element: <PublicLayout />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: 'privacy',
        element: <PrivacyPublic />,
      },
      {
        path: '*',
        element: (
          <Page component={<NotFound isPublic />} pageTitle='page-title.not-found' />
        ),
      },
    ],
  },
  {
    path: '/app',
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <Page component={<Dashboard />} pageTitle='page-title.dashboard' />
        ),
      },
      {
        path: 'project',
        children: [
          {
            path: 'create',
            element: (
              <Page
                component={<CreateProject />}
                pageTitle='page-title.create-project'
              />
            ),
          },
          {
            path: ':projectId/edit',
            element: (
              <Page
                component={<CreateProject edit />}
                pageTitle='page-title.edit-project'
              />
            ),
          },
          {
            path: ':projectId',
            element: (
              <Page
                component={<ProjectDetails />}
                pageTitle='page-title.project-details'
              />
            ),
          },
          {
            path: ':projectId/:releaseId/keys',
            element: (
              <Page
                component={<KeysScreen />}
                pageTitle='page-title.add-keys'
              />
            ),
          },
          {
            path: ':projectId/:releaseId/translate',
            element: (
              <Page
                component={<TranslateScreen />}
                pageTitle='page-title.translate-select'
              />
            ),
          },
          {
            path: ':projectId/:releaseId/translate/:lang',
            element: (
              <Page
                component={<TranslateScreen />}
                pageTitle='page-title.translate'
              />
            ),
          },
          {
            path: ':projectId/:releaseId/translations',
            element: (
              <Page
                component={<ReleaseTranslations />}
                pageTitle='page-title.translations'
              />
            ),
          },
          {
            path: ':projectId/translations',
            element: (
              <Page
                component={<ProjectTranslations />}
                pageTitle='page-title.translations'
              />
            ),
          },
          {
            path: '*',
            element: (
              <Page component={<NotFound />} pageTitle='page-title.not-found' />
            ),
          },
        ],
      },
      {
        path: 'privacy',
        element: <Page component={<Privacy />} pageTitle='footer.privacy' />,
      },
      {
        path: '*',
        element: (
          <Page component={<NotFound />} pageTitle='page-title.not-found' />
        ),
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
