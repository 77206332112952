import { faCheck, faEye, faEyeSlash, faFile, faFileLines, faFont, faKey, faRightLong, faRobot, faUser, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { ActionLog, LogActionCode, supportedLanguages } from "../../types/misc";
import { ReleaseStatus } from "../../types/project";
import i18n from "../../util/i18n";
import { projectRoles, User } from "../../types/user";

export type ActionBadgeConfig = {
    icon: IconDefinition;
    colour: 'success' | 'danger' | 'warning' | 'primary' | 'neutral';
}

export type ProcessedActivityLogData = ActionLog & {
    profileBadge: ActionBadgeConfig;
};

const mapReleaseStatus = (status: string) => {
    if (status === ReleaseStatus.ClientReviewPreTranslation || status === ReleaseStatus.ClientReviewPostTranslation) {
        return i18n.t(`release-status.${status.toLowerCase()}.long`);
    } else if (status === ReleaseStatus.InDevelopment || status === ReleaseStatus.Translating || status === ReleaseStatus.Released) {
        return i18n.t(`release-status.${status.toLowerCase()}`);
    } else {
        return status;
    }
}

export const mapLogAdditionalInfo = (info?: Record<string, any>) => {
    if (!info) {
        return undefined;
    }

    const newObj: Record<string, any> = { ...info };

    if (info.lang && supportedLanguages.includes(info.lang)) {
        newObj.lang = i18n.t(`languages.${info.lang.toLowerCase()}`)
    }

    if (info.prevStatus) {
        newObj.prevStatus = mapReleaseStatus(info.prevStatus);
    }

    if (info.newStatus) {
        newObj.newStatus = mapReleaseStatus(info.newStatus);
    }

    if (info.userRole && projectRoles.includes(info.userRole)) {
        newObj.userRole = i18n.t(`roles.${info.userRole.toLowerCase()}`)
    }

    if (info.prevUserRole && projectRoles.includes(info.prevUserRole)) {
        newObj.prevUserRole = i18n.t(`roles.${info.prevUserRole.toLowerCase()}`)
    }

    if (info.newUserRole && projectRoles.includes(info.newUserRole)) {
        newObj.newUserRole = i18n.t(`roles.${info.newUserRole.toLowerCase()}`)
    }

    if (info.user && typeof info.user !== 'string') {
        newObj.user = info.user.name ?? 'Unknown';
        newObj.userId = `${info.user.email} (${info.user.userId})`
    }

    return newObj;
}

export const getActionBadge = (actionCode: LogActionCode): ActionBadgeConfig => {
    switch(actionCode) {
        case LogActionCode.CreateProject: {
            return {
                icon: faFile,
                colour: 'primary'
            }
        }
        case LogActionCode.UpdateProject: {
            return {
                icon: faFile,
                colour: 'warning'
            }
        }
        case LogActionCode.CreateProjectRelease: {
            return {
                icon: faFileLines,
                colour: 'primary'
            }
        }
        case LogActionCode.ApproveRelease: {
            return {
                icon: faCheck,
                colour: 'success'
            }
        }
        case LogActionCode.AddKeys: {
            return {
                icon: faKey,
                colour: 'neutral'
            }
        }
        case LogActionCode.UpdateKeys: {
            return {
                icon: faKey,
                colour: 'warning'
            }
        }
        case LogActionCode.DeleteKeys: {
            return {
                icon: faKey,
                colour: 'danger'
            }
        }
        case LogActionCode.UpdateTranslationValues: {
            return {
                icon: faFont,
                colour: 'neutral'
            }
        }
        case LogActionCode.BulkAutoTranslate: {
            return {
                icon: faRobot,
                colour: 'warning'
            }
        }
        case LogActionCode.ReviewAllKeys:
        case LogActionCode.ReviewAllTranslations:
        case LogActionCode.ReviewSingleKey:
        case LogActionCode.ReviewSingleTranslation: {
            return {
                icon: faEye,
                colour: 'neutral'
            }
        }
        case LogActionCode.UnreviewAllKeys:
        case LogActionCode.UnreviewAllTranslations:
        case LogActionCode.UnreviewSingleKey:
        case LogActionCode.UnreviewSingleTranslation: {
            return {
                icon: faEyeSlash,
                colour: 'neutral'
            }
        }
        case LogActionCode.ReleaseToGit: {
            return {
                icon: faRightLong,
                colour: 'success'
            }
        }
        case LogActionCode.AddUserToProject: {
            return {
                icon: faUser,
                colour: 'success'
            }
        }
        case LogActionCode.UpdateUserRole: {
            return {
                icon: faUser,
                colour: 'warning'
            }
        }
        case LogActionCode.RemoveUserFromProject: {
            return {
                icon: faUser,
                colour: 'danger'
            }
        }
    }
}

export const checkSystemUser = (user: User): User => {
    if (user.id === 'SYSTEM') {
        const name = i18n.t('activity-log.system-user');
        return {
            ...user,
            name: {
                full: name,
                forename: name,
            }
        }
    }

    return user;
}

export const processActivityLogData = (logData?: ActionLog[]): ProcessedActivityLogData[] | undefined => {
    return logData?.map((log) => (
            {
                ...log,
                additionalInfo: mapLogAdditionalInfo(log.additionalInfo),
                profileBadge: getActionBadge(log.actionCode),
                user: checkSystemUser(log.user)
            }));
}

export const mapAdditionalInfoKey = (key: string) => {
    switch(key) {
        case 'releaseName': {
            return 'activity-log.additional-info.release-name';
        }
        case 'projectName': {
            return 'activity-log.additional-info.project-name';
        }
        case 'prevStatus': {
            return 'activity-log.additional-info.prev-status';
        }
        case 'newStatus': {
            return 'activity-log.additional-info.new-status';
        }
        case 'count': {
            return 'activity-log.additional-info.count';
        }
        case 'keys': {
            return 'activity-log.additional-info.keys';
        }
        case 'key': {
            return 'activity-log.additional-info.key';
        }
        case 'lang': {
            return 'activity-log.additional-info.lang';
        }
        case 'autoApproved': {
            return 'activity-log.additional-info.auto-approved';
        }
        case 'addedUsers': {
            return 'activity-log.additional-info.added-users'
        }
        case 'updatedUsers': {
            return 'activity-log.additional-info.updated-users'
        }
        case 'removedUsers': {
            return 'activity-log.additional-info.removed-users'
        }
        case 'prevName': {
            return 'activity-log.additional-info.prev-name'
        }
        case 'newName': {
            return 'activity-log.additional-info.new-name'
        }
        case 'patUpdated': {
            return 'activity-log.additional-info.pat-updated'
        }
        case 'user': {
            return 'activity-log.additional-info.user'
        }
        case 'userId': {
            return 'activity-log.additional-info.user-id'
        }
        case 'userRole': {
            return 'activity-log.additional-info.user-role'
        }
        case 'prevUserRole': {
            return 'activity-log.additional-info.prev-user-role'
        }
        case 'newUserRole': {
            return 'activity-log.additional-info.new-user-role'
        }
        default: {
            return key;
        }
    }
}