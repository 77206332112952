import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  DialogContent,
  DialogTitle,
  Link,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

export type GitSetupGuideProps = {
  isOpen: boolean;
  onClose: () => void;
};

const GitSetupGuide = ({ isOpen, onClose }: GitSetupGuideProps) => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalDialog maxWidth={'700px'}>
        <DialogTitle>{t('create-project.git.setup.title')}</DialogTitle>
        <ModalClose />
        <DialogContent>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <Typography>{t('create-project.git.setup.intro')}</Typography>
            <AccordionGroup size='lg'>
              <Accordion>
                <AccordionSummary sx={{ fontWeight: 600 }}>
                  {t('create-project.git.setup.repo.title')}
                </AccordionSummary>
                <AccordionDetails sx={{ fontSize: '16px' }}>
                  <Typography sx={{ fontStyle: 'italic' }}>
                    {t('create-project.git.setup.repo.perms')}
                  </Typography>
                  <ol>
                    <li>
                      <Trans
                        i18nKey='create-project.git.setup.repo.one'
                        components={{
                          gitlink: (
                            <Link
                              component={RouterLink}
                              to={'https://github.com/new'}
                              target='_blank'
                            />
                          ),
                        }}
                      />
                    </li>
                    <li>{t('create-project.git.setup.repo.two')}</li>
                    <li>
                      <Typography>
                        <Trans
                          i18nKey='create-project.git.setup.repo.three'
                          components={{
                            codestyle: (
                              <Typography
                                variant='soft'
                                fontFamily={'monospace'}
                                sx={{ fontSize: '14px' }}
                              />
                            ),
                          }}
                        />
                      </Typography>
                    </li>
                    <li>{t('create-project.git.setup.repo.four')}</li>
                    <li>{t('create-project.git.setup.repo.five')}</li>
                    <li>{t('create-project.git.setup.repo.six')}</li>
                  </ol>
                </AccordionDetails>
              </Accordion>
            </AccordionGroup>
            <AccordionGroup size='lg'>
              <Accordion>
                <AccordionSummary sx={{ fontWeight: 600 }}>
                  {t('create-project.git.setup.pat.title')}
                </AccordionSummary>
                <AccordionDetails sx={{ fontSize: '16px' }}>
                  <Typography sx={{ fontStyle: 'italic' }}>
                    {t('create-project.git.setup.pat.perms')}
                  </Typography>
                  <ol>
                    <li>
                      <Trans
                        i18nKey='create-project.git.setup.pat.one'
                        components={{
                          gitlink: (
                            <Link
                              component={RouterLink}
                              to={'https://github.com/settings'}
                              target='_blank'
                            />
                          ),
                        }}
                      />
                    </li>
                    <li>{t('create-project.git.setup.pat.two')}</li>
                    <li>{t('create-project.git.setup.pat.three')}</li>
                    <li>{t('create-project.git.setup.pat.four')}</li>
                    <li>{t('create-project.git.setup.pat.five')}</li>
                    <li>{t('create-project.git.setup.pat.six')}</li>
                    <li>{t('create-project.git.setup.pat.seven')}</li>
                    <li>{t('create-project.git.setup.pat.eight')}</li>
                    <li>{t('create-project.git.setup.pat.nine')}</li>
                    <li>
                      {t('create-project.git.setup.pat.ten')}
                      <ul>
                        <li>
                          {t('create-project.git.setup.pat.ten-scope-one')}
                        </li>
                        <li>
                          {t('create-project.git.setup.pat.ten-scope-two')}
                        </li>
                      </ul>
                    </li>
                    <li>{t('create-project.git.setup.pat.eleven')}</li>
                  </ol>
                  <div>{t('create-project.git.setup.pat.copy-warning')}</div>
                </AccordionDetails>
              </Accordion>
            </AccordionGroup>
          </div>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default GitSetupGuide;
