import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Table,
  Typography,
  useTheme,
} from '@mui/joy';
import { css } from 'aphrodite';
import React from 'react';
import keysTableStyles from '../../keys-table/keys-table.styles';
import {
  TranslationKeyData,
  TranslationKeyFailureDetail,
  TranslationKeyInput,
} from '../../../types/translation';
import * as Yup from 'yup';
import generalUtil from '../../../util/general';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faCheck,
  faFileImport,
  faPencil,
  faTrashCan,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import DeleteConfirmationModal from '../../keys-table/delete-confirmation-modal/delete-confirmation-modal';
import ErrorMessage from '../../error-message/error-message';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export type ImportKeyPreviewTableProps = {
  keys: TranslationKeyInput[];
  existingKeys: TranslationKeyData[];
  isSubmitting?: boolean;
  isAddKeyError?: boolean;
  addKeyError?: unknown;
  onConfirm: (keys: TranslationKeyInput[]) => void;
  onCancel: () => void;
};

const ImportKeyPreviewTable = ({
  keys,
  existingKeys,
  isSubmitting,
  isAddKeyError,
  addKeyError,
  onConfirm,
  onCancel,
}: ImportKeyPreviewTableProps) => {
  const [rows, setRows] = React.useState<TranslationKeyInput[]>(keys);
  const [rowEditing, setRowEditing] = React.useState<number | undefined>();
  const [deleteCandidate, setDeleteCandidate] = React.useState<
    string | undefined
  >(undefined);

  const joyUiTheme = useTheme();
  const { t } = useTranslation();

  React.useEffect(() => {
    setRows(keys);
  }, [keys]);

  const allKeys = React.useMemo(
    () =>
      rowEditing !== undefined
        ? [
            ...existingKeys.map((data) => data.key),
            ...rows.slice(0, rowEditing).map((data) => data.key),
            ...rows.slice(rowEditing + 1).map((data) => data.key),
          ]
        : [
            ...existingKeys.map((data) => data.key),
            ...rows.map((data) => data.key),
          ],
    [rows, existingKeys, rowEditing],
  );

  const formValidationSchema = Yup.object().shape({
    key: Yup.string()
      .required(t('keys-table.errors.key.required'))
      .max(255, t('keys-table.errors.key.length', { maxLength: 255 }))
      .matches(generalUtil.KEY_REGEX, t('keys-table.errors.key.invalid'))
      .notOneOf(allKeys, t('keys-table.errors.key.duplicate')),
    primaryValue: Yup.string()
      .required(t('keys-table.errors.primary-value.required'))
      .max(
        2000,
        t('keys-table.errors.primary-value.length', { maxLength: 2000 }),
      ),
    placeholder: Yup.boolean().optional(),
    context: Yup.string().max(
      2000,
      t('keys-table.errors.context.length', { maxLength: 2000 }),
    ),
  });

  const {
    control,
    register,
    reset,
    getValues,
    formState: { isValid, errors },
  } = useForm<TranslationKeyInput>({
    resolver: yupResolver(formValidationSchema),
    mode: 'all',
  });

  const handleRowUpdate = (rowIndex: number) => {
    const updatedRows = rows.map((data, index) =>
      index === rowIndex ? getValues() : data,
    );
    setRows(updatedRows);
    setRowEditing(undefined);
    reset();
  };

  const handleRowDelete = () => {
    const updatedRows = rows.filter((data) => data.key !== deleteCandidate);
    setRows(updatedRows);
    setDeleteCandidate(undefined);
  };

  const handleConfirmImport = () => {
    onConfirm(rows);
  };

  return (
    <>
      {keys.length > 0 && (
        <>
          <DeleteConfirmationModal
            isOpen={!!deleteCandidate}
            handleCancel={() => setDeleteCandidate(undefined)}
            handleConfirm={handleRowDelete}
            keyValue={deleteCandidate}
          />
          <div style={{ overflow: 'auto', maxHeight: '500px' }}>
            <Table>
              <thead>
                <tr>
                  <th
                    className={css(keysTableStyles.heading)}
                    style={{ width: '20%' }}
                  >
                    {t('keys-table.headings.key')}
                  </th>
                  <th className={css(keysTableStyles.heading)}>
                    {t('keys-table.headings.primary-value')}
                  </th>
                  <th
                    className={css(keysTableStyles.heading)}
                    style={{ width: '12%' }}
                  >
                    {t('keys-table.headings.placeholder')}
                  </th>
                  <th className={css(keysTableStyles.heading)}>
                    {t('keys-table.headings.context')}
                  </th>
                  <th
                    className={css(keysTableStyles.heading)}
                    style={{ width: '8%' }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {rows.map((data, index) => (
                  <tr key={`${data.key}-${index}`}>
                    <td>
                      {rowEditing === index ? (
                        <>
                          <Controller
                            name='key'
                            control={control}
                            defaultValue={data.key}
                            render={({ field: props }) => (
                              <Input
                                error={!!errors.key}
                                placeholder={`${t('keys-table.headings.key')}...`}
                                onChange={(e) => {
                                  props.onChange(e.target.value.toLowerCase());
                                }}
                                value={props.value}
                              />
                            )}
                          />
                          {!!errors.key && (
                            <FormHelperText
                              sx={{
                                color: joyUiTheme.vars.palette.danger[500],
                                display: 'inline-block',
                              }}
                            >
                              {errors.key.message}
                            </FormHelperText>
                          )}
                        </>
                      ) : (
                        <div style={{ display: 'flex' }}>
                          <Typography
                            fontFamily={'monospace'}
                            variant='soft'
                            sx={{ overflowWrap: 'anywhere' }}
                          >
                            {data.key}
                          </Typography>
                        </div>
                      )}
                    </td>
                    <td>
                      {rowEditing === index ? (
                        <FormControl>
                          <Input
                            {...register('primaryValue')}
                            placeholder={`${t('keys-table.headings.primary-value')}...`}
                            defaultValue={data.primaryValue}
                            error={!!errors.primaryValue}
                          />
                          {!!errors.primaryValue && (
                            <FormHelperText
                              sx={{
                                color: joyUiTheme.vars.palette.danger[500],
                              }}
                            >
                              {errors.primaryValue.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      ) : (
                        <div>{data.primaryValue}</div>
                      )}
                    </td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {rowEditing === index ? (
                          <Controller
                            name='placeholder'
                            control={control}
                            defaultValue={data.placeholder}
                            render={({ field: props }) => (
                              <Checkbox
                                checked={props.value}
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        ) : (
                          <Checkbox checked={data.placeholder} disabled />
                        )}
                      </div>
                    </td>
                    <td>
                      {rowEditing === index ? (
                        <FormControl>
                          <Input
                            {...register('context')}
                            placeholder={`${t('keys-table.headings.context')}...`}
                            defaultValue={data.context}
                            error={!!errors.context}
                          />
                          {!!errors.context && (
                            <FormHelperText
                              sx={{
                                color: joyUiTheme.vars.palette.danger[500],
                              }}
                            >
                              {errors.context.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      ) : (
                        <div style={{ padding: '0 8px' }}>
                          <Typography noWrap>{data.context}</Typography>
                        </div>
                      )}
                    </td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          gap: '8px',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {rowEditing !== index ? (
                          <>
                            <IconButton
                              color='primary'
                              variant='solid'
                              aria-label={t('common.edit')}
                              size='sm'
                              onClick={() => setRowEditing(index)}
                              disabled={rowEditing !== undefined}
                            >
                              <FontAwesomeIcon icon={faPencil} />
                            </IconButton>
                            <IconButton
                              color='danger'
                              variant='solid'
                              aria-label={t('common.delete')}
                              size='sm'
                              disabled={rowEditing !== undefined}
                              onClick={() => setDeleteCandidate(data.key)}
                            >
                              <FontAwesomeIcon icon={faTrashCan} />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton
                              color='success'
                              variant='solid'
                              aria-label={t('common.confirm')}
                              size='sm'
                              onClick={() => handleRowUpdate(index)}
                              disabled={!isValid}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </IconButton>
                            <IconButton
                              color='danger'
                              variant='solid'
                              aria-label={t('common.cancel')}
                              size='sm'
                              onClick={() => {
                                setRowEditing(undefined);
                                reset();
                              }}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </IconButton>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <ErrorMessage isError={isAddKeyError ?? false} error={addKeyError}>
            {axios.isAxiosError(addKeyError) &&
              addKeyError.response?.data?.error?.detail && (
                <div style={{ marginTop: '8px' }}>
                  <div>{t('keys-table.errors.save-failed')}</div>
                  <ul>
                    {(
                      addKeyError.response?.data?.error
                        ?.detail as TranslationKeyFailureDetail[]
                    )?.map((failure, index) => (
                      <li key={`${failure.input.key}-${index}`}>
                        {failure.input.key} - {failure.reason}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
          </ErrorMessage>
        </>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: keys.length === 0 ? 'flex-start' : 'space-between',
        }}
      >
        <Button
          startDecorator={<FontAwesomeIcon icon={faAngleLeft} />}
          color='neutral'
          onClick={onCancel}
          disabled={isSubmitting}
        >
          {t('common.back')}
        </Button>
        {keys.length > 0 && (
          <Button
            startDecorator={<FontAwesomeIcon icon={faFileImport} />}
            onClick={handleConfirmImport}
            loading={isSubmitting}
          >
            {t('key-import.confirm-import', { num: rows.length })}
          </Button>
        )}
      </div>
    </>
  );
};

export default ImportKeyPreviewTable;
