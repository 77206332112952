import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import PublicNavBar from '../public-navigation-bar/public-navigation-bar';
import Footer from '../footer/footer';
import CookiePreferences from '../../cookie-preferences/cookie-preferences';
import RouterScroll from '../../../util/router-scroll';
import { Page } from '../../../types/navigation';

const pagesWithNonStaticNavbar = [Page.Landing] as string[];

const PublicLayout = () => {
  const location = useLocation();
  return (
    <>
      <RouterScroll />
      <PublicNavBar isStatic={!pagesWithNonStaticNavbar.includes(location.pathname)} />
      <CookiePreferences isPublic />
      <Outlet />
      <Footer isPublic />
    </>
  );
};

export default PublicLayout;
