import React from 'react';
import Heading from '../../components/heading/heading';
import { Button, Link, Table, Typography } from '@mui/joy';
import { faAngleLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink } from 'react-router-dom';
import Message from '../../components/message/message';
import { useTranslation } from 'react-i18next';
import generalUtil from '../../util/general';
import { Page } from '../../types/navigation';

type CookieTableData = {
  name: string;
  category: string;
  purpose: string | string[];
};

const cookieTableData: CookieTableData[] = [
  {
    name: 'auth0.is.authenticated',
    category: 'Essential',
    purpose:
      'Storing and remembering your authentication state with the Auth0 Authentication Service.',
  },
  {
    name: '_legacy_auth0.is.authenticated',
    category: 'Essential',
    purpose:
      'Storing and remembering your authentication state with the Auth0 Authentication Service.',
  },
  {
    name: 'auth0',
    category: 'Essential',
    purpose: 'Used for Authentication with the Auth0 Authentication Service.',
  },
  {
    name: 'auth0_compat',
    category: 'Essential',
    purpose: 'Used for Authentication with the Auth0 Authentication Service.',
  },
  {
    name: 'did',
    category: 'Essential',
    purpose: 'Used by Auth0 for Device Identification for Attack prevention.',
  },
  {
    name: 'did_compat',
    category: 'Essential',
    purpose: 'Used by Auth0 for Device Identification for Attack prevention.',
  },
  {
    name: 'ot-lang',
    category: 'Essential',
    purpose: [
      'Used by OneTranslate to store your display language preference.',
      'Stored for 1 year, and refreshed when the display language is changed within the Application.',
    ],
  },
  {
    name: 'ot-consent-functional',
    category: 'Essential',
    purpose: [
      'Used by OneTranslate to track whether you have given consent for optional functional cookies to be used by the Application.',
      'Stored for 1 year, and refreshed when your consent preferences are updated.',
    ],
  },
  {
    name: 'ot-highlight-untranslated',
    category: 'Essential',
    purpose: [
      'Used by OneTranslate to retain your preference relating to whether untranslated translations should be highlighted.',
      'Only used if consent has been granted to use non-essential functional cookies.',
      'Stored for 1 year, and refreshed when your preference related to untranslated translations highlighting is updated. Deleted if consent to use non-essential cookies is revoked.',
    ],
  },
];

export type PrivacyPageProps = {
  isPublic?: boolean;
};

const Privacy = ({ isPublic }: PrivacyPageProps) => {
  const { t, i18n } = useTranslation();

  const lastUpdatedDate = React.useMemo(
    () =>
      generalUtil.formatDate('2025-02-07T00:00:00.000Z', i18n.language, {
        dateStyle: 'full',
      }),
    [i18n.language],
  );

  return (
    <>
      {!isPublic && (
        <Link
          startDecorator={<FontAwesomeIcon icon={faAngleLeft} />}
          fontSize={'18px'}
          component={RouterLink}
          to={Page.Dashboard}
        >
          {t('page-title.dashboard')}
        </Link>
      )}
      <Heading style={{ margin: '8px 0px 24px' }}>{t('privacy.title')}</Heading>
      {i18n.language !== 'en' && (
        <div style={{ marginBottom: '16px' }}>
          <Message variant='primary' title={t('privacy.lang-warning-title')}>
            <Typography sx={{ color: 'inherit' }}>
              {t('privacy.lang-warning-body')}{' '}
              <Link
                component={RouterLink}
                to={'mailto:beg118@student.bham.ac.uk'}
              >
                beg118@student.bham.ac.uk
              </Link>
            </Typography>
          </Message>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Typography>
          {t('privacy.last-updated')}: {lastUpdatedDate}
        </Typography>
        <Button
          startDecorator={<FontAwesomeIcon icon={faDownload} />}
          onClick={() =>
            window.open(
              '/file/OneTranslate_PrivacyPolicy_Feb2025.pdf',
              '_blank',
            )
          }
        >
          {t('privacy.download')}
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
        <div>
          <Typography>
            This Privacy Policy explains how OneTranslate collects, uses, and
            protects your personal data when you use our web application.
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography level='h2'>1. Introduction</Typography>
          <div
            style={{
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              gap: 'inherit',
            }}
          >
            <Typography>
              1.1. OneTranslate is a web application developed by an individual
              as part of a project during the course of a Degree Programme at
              the School of Computer Science, University of Birmingham.
            </Typography>
            <Typography>
              1.2. References to “OneTranslate”, “we”, “us” or “our” in this
              policy refer to the project and its sole developer. The web
              application, accessible at{' '}
              <Link component={RouterLink} to={'https://onetranslate.co.uk'}>
                https://onetranslate.co.uk
              </Link>
              , will be referred to as “the Application”, or just “Application”.
            </Typography>
            <Typography>
              1.3. The data controller responsible for processing personal data
              in connection with OneTranslate is Benjamin Griffiths (
              <Link
                component={RouterLink}
                to={'mailto:beg118@student.bham.ac.uk'}
              >
                beg118@student.bham.ac.uk
              </Link>
              ).
            </Typography>
            <Typography>
              1.4. If you have any questions regarding this policy or how your
              data is processed, please contact us at the email address above.
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography level='h2'>2. What data do we collect?</Typography>
          <div
            style={{
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              gap: 'inherit',
            }}
          >
            <Typography>
              2.1. OneTranslate collects the following personal data:
            </Typography>
            <ul style={{ margin: 0 }}>
              <li>
                <Typography>Name</Typography>
              </li>
              <li>
                <Typography>Email Address</Typography>
              </li>
              <li>
                <Typography>IP Address</Typography>
              </li>
            </ul>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography level='h2'>3. How do we collect your data?</Typography>
          <div
            style={{
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              gap: 'inherit',
            }}
          >
            <Typography>
              3.1. You directly provide OneTranslate most of the data we
              collect. We collect and process data when you:
            </Typography>
            <div
              style={{
                marginLeft: '32px',
                display: 'flex',
                flexDirection: 'column',
                gap: 'inherit',
              }}
            >
              <Typography>
                3.1.1. Sign up or log in to the Application through our Auth0
                Managed Authentication Service. Auth0 stores the name and email
                address you provide when signing up, and stores your IP address
                whenever you login to the Application
              </Typography>
              <Typography>
                3.1.2. Use or view the Application, which results in your IP
                address being logged by our server infrastructure, as well as
                the Application itself.
              </Typography>
            </div>
            <Typography>
              3.2. We do not collect personal data from third parties.
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography level='h2'>4. How will we use your data?</Typography>
          <div
            style={{
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              gap: 'inherit',
            }}
          >
            <Typography>4.1. We collect your data to:</Typography>
            <div
              style={{
                marginLeft: '32px',
                display: 'flex',
                flexDirection: 'column',
                gap: 'inherit',
              }}
            >
              <Typography>
                4.1.1. Authenticate users and allow access to the Application
                (via our Auth0 Managed Authentication Service)
              </Typography>
              <Typography>
                4.1.2. Maintain server and Application security (via IP logging)
              </Typography>
            </div>
            <Typography>
              4.2. We do not use your data for marketing purposes, nor do we
              share your personal data with third parties beyond the necessary
              service providers (namely Auth0 for authentication)
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography level='h2'>5. How will we use your data?</Typography>
          <div
            style={{
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              gap: 'inherit',
            }}
          >
            <Typography>
              5.1. Your <b>name and email address</b> is stored by Auth0, LLC -
              a third-party authentication provider.. We do not store this data
              directly. Auth0, LLC is a subsidiary of Okta. Please refer to
              Okta's Privacy Policy for further details:{' '}
              <Link
                component={RouterLink}
                to={'https://www.okta.com/privacy-policy/'}
                target='_blank'
              >
                https://www.okta.com/privacy-policy/
              </Link>
              . All data held by Auth0 in relation to OneTranslate is stored
              within a UK datacentre.
            </Typography>
            <Typography>
              5.2. Your <b>IP address</b> is logged and stored on a secured
              server located in a UK datacentre. Logs are retained for 90 days
              and are automatically deleted thereafter.
            </Typography>
            <Typography>
              5.3. Your IP address is also logged and stored by Auth0, LLC when
              you login or sign up to the Application. Auth0, LLC is a
              subsidiary of Okta. Please refer to Okta's Privacy Policy for
              further details:{' '}
              <Link
                component={RouterLink}
                to={'https://www.okta.com/privacy-policy/'}
                target='_blank'
              >
                https://www.okta.com/privacy-policy/
              </Link>
              . All data held by Auth0 in relation to OneTranslate is stored
              within a UK datacentre.
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography level='h2'>6. Your data protection rights</Typography>
          <div
            style={{
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              gap: 'inherit',
            }}
          >
            <Typography>
              6.1. We would like to make sure you are fully aware of all of your
              data protection rights. Every user of the Application is entitled
              to the following:
            </Typography>
            <div
              style={{
                marginLeft: '32px',
                display: 'flex',
                flexDirection: 'column',
                gap: 'inherit',
              }}
            >
              <Typography>
                6.1.1. <b>The right to access</b> - You have the right to
                request copies of your personal data held by us.
              </Typography>
              <Typography>
                6.1.2. <b>The right to rectification</b> - You have the right to
                request that we correct any information you believe is
                inaccurate. You also have the right to request us to complete
                the information you believe is incomplete.
              </Typography>
              <Typography>
                6.1.3. <b>The right to erasure</b> - You have the right to
                request that we erase your personal data, under certain
                conditions.
              </Typography>
              <Typography>
                6.1.4. <b>The right to restrict processing</b> - You have the
                right to request that we restrict the processing of your
                personal data, under certain conditions.
              </Typography>
              <Typography>
                6.1.5. <b>The right to object to processing</b> - You have the
                right to object to our processing of your personal data, under
                certain conditions.
              </Typography>
              <Typography>
                6.1.6. <b>The right to data portability</b> - You have the right
                to request that we transfer the data that we have collected to
                another organisation, or directly to you, under certain
                conditions.
              </Typography>
            </div>
            <Typography>
              6.2. If you make a request, we have 1 month to respond to you.
            </Typography>
            <Typography>
              6.3. If you would like to exercise any of these rights, please
              contact us via:{' '}
              <Link
                component={RouterLink}
                to={'mailto:beg118@student.bham.ac.uk'}
              >
                beg118@student.bham.ac.uk
              </Link>
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography level='h2'>7. Cookies</Typography>
          <div
            style={{
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              gap: 'inherit',
            }}
          >
            <Typography>
              7.1. Cookies are small text files placed on your computer. Further
              information about cookies can be found here:{' '}
              <Link component={RouterLink} to={'https://allaboutcookies.org'}>
                https://allaboutcookies.org
              </Link>
              .
            </Typography>
            <Typography>
              7.2. We use the following cookies in the Application for the
              following purposes:
            </Typography>
            <div>
              <Table variant='soft' borderAxis='both'>
                <thead>
                  <tr>
                    <th>Cookie Name</th>
                    <th>Category</th>
                    <th>Purpose</th>
                  </tr>
                </thead>
                <tbody>
                  {cookieTableData.map((data) => (
                    <tr key={data.name}>
                      <td>
                        <Typography>{data.name}</Typography>
                      </td>
                      <td>
                        <Typography>{data.category}</Typography>
                      </td>
                      <td>
                        {Array.isArray(data.purpose) ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '16px',
                            }}
                          >
                            {data.purpose.map((purpose, index) => (
                              <Typography key={index}>{purpose}</Typography>
                            ))}
                          </div>
                        ) : (
                          <Typography>{data.purpose}</Typography>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Typography>
              7.3. You can configure your browser to reject cookies, but this
              may impact some functionalities of the Application. Instructions
              for configuring your browser to reject cookies can be found here:{' '}
              <Link
                component={RouterLink}
                to={'https://allaboutcookies.org'}
                target='_blank'
              >
                https://allaboutcookies.org
              </Link>
              .
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography level='h2'>
            8. Privacy policies of other websites
          </Typography>
          <div
            style={{
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              gap: 'inherit',
            }}
          >
            <Typography>
              8.1. The Application may contain links to external websites. This
              privacy policy applies only to the Application, so please review
              the privacy policies of external websites when visiting them.
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography level='h2'>9. Changes to our privacy policy</Typography>
          <div
            style={{
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              gap: 'inherit',
            }}
          >
            <Typography>
              9.1. We keep this privacy policy under regular review. Any updates
              will be posted on the privacy page within the Application (
              <Link
                component={RouterLink}
                to={'https://onetranslate.co.uk/privacy'}
              >
                https://onetranslate.co.uk/privacy
              </Link>
              ), and updated in this document.
            </Typography>
            <Typography>
              9.2. This policy was last updated on 7th February 2025.
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography level='h2'>10. How to contact us</Typography>
          <div
            style={{
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              gap: 'inherit',
            }}
          >
            <Typography>
              10.1. If you have any questions about our privacy policy or would
              like to exercise your data protection rights, please contact us
              via:{' '}
              <Link
                component={RouterLink}
                to={'mailto:beg118@student.bham.ac.uk'}
              >
                beg118@student.bham.ac.uk
              </Link>
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography level='h2'>
            11. How to contact the appropriate authority
          </Typography>
          <div
            style={{
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              gap: 'inherit',
            }}
          >
            <Typography>
              11.1. If you feel that we have not addressed your concerns
              satisfactorily, you can contact the UK Information Commissioner's
              Office (ICO):
            </Typography>
            <ul style={{ margin: 0 }}>
              <li>
                <Typography>
                  Website:{' '}
                  <Link
                    component={RouterLink}
                    to={'https://ico.org.uk'}
                    target='_blank'
                  >
                    https://ico.org.uk
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography>
                  Address: Information Commissioner's Office, Wycliffe House,
                  Water Lane, Wilmslow, Cheshire, SK9 5AF
                </Typography>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
